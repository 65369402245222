@keyframes slideInleft {
	from {
		opacity: 0;
		transform: translateX(calc(100% + 32px));
	}

	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes animation-sqvad5 {
    0% {
        transform: scale(1.05);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes animation-13rvueg {
    0% {
        transform: translateY(-100%);
    }  
}

@keyframes animation-1pw8ltw {
    0% {
        transform: translate(0px, -100%);
    }
}