.coin-input {
    width: 100%;
    border: 1px solid transparent;
    position: relative;
    display: block;
    height: 90px;
    min-height: auto;
    padding: 0px;
    border-radius: 8px;
    background: rgba(15, 17, 26, 0.4);

    .max-balace {
        position: absolute;
        bottom: 15px;
        right: 24px;
    }

    .input {
        padding: 13.5px 24px 46px;
        outline: none;
        border: none;
        background: none;
        width: 100%;
        height: 100%;
        color: rgb(255, 255, 255);
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 22px;

        &:focus-within {
            border: 1px solid rgb(255, 255, 193);
            box-shadow: rgb(255, 252, 57) 0px 0px 1px inset, rgb(255, 93, 0) 0px 0px 4px;
            border-radius: 8px;
        }
    }

    .input-content {
        pointer-events: none;
        padding: 14px 24px 0px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        .input-end-top {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: end;
            justify-content: flex-end;
            padding-bottom: 12px;

            .coin-type {
                padding: 0px 0px 0px 8px;
                color: rgb(255, 255, 255);
                font-size: 22px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 700;
                font-style: normal;
            }
        }

        .input-end-bottom {
            display: flex;
            column-gap: 8px;


            .balance {
                margin: -6px 51px 0px auto;
                color: rgb(177, 182, 198);
                font-size: 12px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 500;
                font-style: normal;
            }
        }
    }
}