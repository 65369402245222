.card {
    width: 100%;
box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 5px;
    background-color: rgb(26, 29, 41);
    border: 0;
    margin-top: 12px;

    .card-body {
        padding: 25px 45px;

        .table-title-box {
            margin-bottom: 24px;

            .table-title {
                font-weight: 600;
                font-size: 18px;
            }
        }

    }

}

@media only screen and (max-width: 425px) {
    .card-body {
        padding: 15px 10px !important;

        .table-title {
            font-size: 15px !important;
        }
    }
}