.nft-card-wrapper {
    border-radius: 8px;
    background: rgba(203, 215, 255, 0.03);
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
    padding: 16px;
    justify-content: space-between;

    .nft-content {
        cursor: pointer;
        
        .nft-title {
            color: rgb(255, 255, 255);
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .img-wrapper {
            display: block;
            position: relative;
            padding-bottom: 100%;
            border-radius: 4px;
    
            img {
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                height: 100%;
                width: 100%;
                border-radius: 4px;
            }
        }
    }

    .btn-group {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        gap: 12px;

        .button {
            width: 50%;
        }
    }

    .progress-bar-wrapper {
        margin-top: 14px;

        .progress-bar {
            height: 4px;
            border-radius: 4px;
            background: linear-gradient(90deg, rgb(114, 242, 56) 0%, rgb(255, 176, 24) 50%, rgb(255, 73, 73) 100%);
        }

        .progress-bar-value {
            display: inline-block;
            text-align: center;
            color: rgb(255, 255, 255);
            width: 56px;
            margin-left: calc(30.9929% - 28px);
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
            position: relative;
            top: -10px;

            svg {
                transform: rotate(180deg);
                filter: drop-shadow(rgb(16, 18, 27) 0px 0px 1px);
            }

            .value {
                position: relative;
                top: -5px;
                font-weight: 800;
            }
        }
    }
}