.header-container {
    padding: 0 20px 0 24px;
    display: flex;
    align-items: center;
    background: rgb(26, 29, 41);
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
}

.header-content {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.logo-img {
    height: 32px;
}

.logo-text {
    background: linear-gradient(54deg, #eac249, #df7a03);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rewards-box {
    font-size: 12px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 800;
    font-style: normal
}

.account-conatiner {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    .wallet-container {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}

.rate-box {
    position: relative;
}

.rate-item-outner {
    scale: 3;
}

.rate-item-inner {
    scale: 1.3;
}

.rate-item-inner {
    position: absolute;
    top: 2px;
    left: 0px;
    height: 100%;
    width: 100%;
}

.cash-box {
    display: flex;
    align-items: center;
}

.cash-total-box {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 6px;
    padding: 3px 16px;
    background: rgb(16, 18, 27);
    font-weight: 500;
    font-style: normal;
    min-height: 42px;
    color: rgb(255, 255, 255) !important;
}

.account-exp {
    height: 5px;
    background: linear-gradient(90deg, rgb(229, 164, 128) 0%, rgb(229, 164, 128) 0%, rgba(203, 215, 255, 0.1) 0%, rgba(203, 215, 255, 0.1) 100%);
}

.text-login {
    margin: 0px 24px 0px 40px;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    font-size: 14px;
    letter-spacing: 0.5px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 800;
    font-style: normal;
    cursor: pointer;
    user-select: none;
}

.navbar-container {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 50%;
    width: 100%;

    .navbar-item {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: end;
        justify-content: flex-end;
        flex-direction: column;
        gap: 6px;
        height: 86%;
        min-height: 86%;
        padding: 0px 8px 5px;
        border-radius: 8px;
        width: 20%;
        color: rgb(177, 182, 198);
        font-size: 11px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
        cursor: pointer;
        user-select: none;

        &.active {
            text-shadow: rgb(255, 93, 0) 0px 0px 10px;
            background: rgba(203, 215, 255, 0.055) !important;
            color: rgb(255, 255, 193) !important;

            .svg-icon {
                color: rgb(255, 255, 193) !important;
                filter: drop-shadow(rgb(255, 93, 0) 0px 0px 6px);
            }
        }
    }
}

.chain-network-active {
    display: flex;
    align-items: center;
    gap: 8px;
}

@media only screen and (max-width: 450px) {
        .logo-img {
            height: 24px;
            margin-right: 12px;
        }

    .account-conatiner {
        .wallet-container {
            gap: 6px;
        }
        .button {
            height: 30px !important;
        }


        .btn--register {
            font-size: 10px !important;
        }

        .chain-network-active {
            font-size: 10px;
        }
    }

}

@media only screen and (max-width: 768px) {
    .header-container {
        flex-direction: column;
        height: 112px;
        padding: 0px 16px 0px 6px !important;

        .header-content {
            padding-right: 0 !important;
            height: 55px;
            margin: 0 !important;
        }

        .button {
            height: 40px;
            font-size: 13px;
            padding: 0 8px;
        }

        .text-login {
            font-size: 13px;
        }

        .chain-network-active {
            .logo {
                width: 24px;
            }
        }
    }

    .rewards-box {
        display: none !important;
    }

    [data-kt-app-message-box=true] .app-wrapper .message-box-container {
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: calc(100vh - 112px);
        top: 112px;
        z-index: 10;
    }

    [data-kt-app-layout=dark-sidebar] .app-sidebar {
        top: 112px;
        width: 100%;
        height: calc(100vh - 112px);
    }

    .app-sidebar {
        .overflow-hidden {
            overflow: unset !important;
        }
    }

    .app-sidebar-footer {
        width: 100%;
        margin: 25px 0 0;
    }

    .drawer-overlay {
        display: none;
    }
}