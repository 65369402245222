.message-box-container {
    background-color: rgb(31, 35, 48);
    display: none;
    right: 0;
    top: 70px;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    padding: 0px 16px 16px;
}

.message-box-action-container {
    //  position: fixed;
    //  bottom: 0;
    color: rgb(177, 182, 198);

    .message-box-action {
        display: flex;
        align-items: center;
        padding-top: 12px;
    }

    .ab-img {
        height: 20px;
        margin-right: 10px;
        cursor: pointer
    }

    .ab-rule {
        margin-right: auto;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        cursor: pointer;
    }

    .ab-max {
        margin-right: 12px;
        font-size: 12px;
        font-weight: 700;
    }
}


.message-box-tab {
    position: relative;
    height: 50px;
    margin: 0px -6px 0px -8px;

    .tab-box {
        height: 50px;
    }


    .action-box {
        right: 0;
        top: 10px;
        position: absolute;
        display: flex;
        gap: 4px;
        align-items: center;
        height: 30px;

        span {
            cursor: pointer;
        }
    }

}

.mesage-tab {
    display: flex;
    gap: 4px;

    .tab-wrapper {
        display: flex;
    }

    &.nav-tabs {
        border: none;
        height: 100%;

    }

    .nav-item {
        color: rgb(255, 255, 255);
        border-radius: 8px;
        height: 30px;
        display: flex;
        margin-top: 10px;
        align-items: center;
    }

    .nav-link {
        white-space: nowrap;
        transition: all 0.1s ease 0s;
        appearance: none;
        border: none;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        cursor: pointer;
        user-select: none;
        font-family: "Geogrotesque Wide", sans-serif;
        font-style: normal;
        height: 30px;
        padding: 0px 10px;
        font-weight: 800;
        font-style: normal;
        text-transform: uppercase;

        &.active {
            background: rgba(203, 215, 255, 0.03);
            border-radius: 8px;
            color: rgb(255, 255, 193) !important;
            text-shadow: rgb(255, 93, 0) 0px 0px 10px;
        }

        &:hover {
            background: rgba(203, 215, 255, 0.075);
            border-radius: 6px;
        }



    }
}

.list-message {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    min-height: 0px;
    padding-right: 4px;
    overflow-wrap: break-word;
    color: rgb(177, 182, 198);
    height: calc(100vh - 215px);
    overflow:scroll;
}

@media only screen and (max-width: 425px) {
    .list-message {
        height: calc(100vh - 270px);
    }
}