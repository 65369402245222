.create-plans {
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 32px;
    max-width: 520px;
    margin: 0 auto;
    vertical-align: middle;
    border-radius: 10px;
    background: rgb(26, 29, 41);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 20px, rgba(255, 255, 255, 0.04) 0px 1px 0px inset, rgba(0, 0, 0, 0.25) 0px -4px 0px inset;

    .description {
        line-height: 1.6;
        word-break: break-word;
        color: rgb(177, 182, 198);
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
    }

    .balace-input {
        margin: 24px 0;
    }

    .rollbot-box {
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 16px;
        margin-bottom: 24px;

        .rollbot-des {
            line-height: 1.5;
            color: rgb(177, 182, 198);
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 14px;
        }

        .rollbot-link {
            text-transform: none;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 700;
            font-style: normal;
            cursor: pointer;
            color: rgb(255, 176, 24);

            &:hover {
                color: rgb(255, 176, 24);
                text-decoration: underline;
            }
        }


    }

    .private-clan {
        opacity: 0.4;
        margin-bottom: 24px;

        .checkbox-private {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            position: relative;
            height: 18px;
            margin: 0px;
            padding-left: 28px;
            cursor: pointer;
            text-transform: uppercase;
            white-space: nowrap;
            color: rgb(177, 182, 198);
            font-size: 13px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                height: 18px;
                width: 18px;
                border: 1px solid rgb(48, 53, 69);
                border-radius: 4px;
                background: rgb(16, 18, 27);
            }
        }
    }

    .button{
        width: 100% !important;
        margin-top: 8px;
        height: 48px;
    }


}

.how-it-work-dialog {
    margin: 0 auto;
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 32px;
    max-width: 640px;
    vertical-align: middle;
    border-radius: 10px;
    animation: 0.15s ease-out 0s 1 normal both running animation-1h5r8ub;
    background: rgb(26, 29, 41);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 20px, rgba(255, 255, 255, 0.04) 0px 1px 0px inset, rgba(0, 0, 0, 0.25) 0px -4px 0px inset;


    .title {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 24px;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        word-break: break-word;
        gap: 12px;
        -webkit-box-pack: center;
        justify-content: center;
        font-size: 22px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
    }

    .work-info {
        margin-bottom: 24px;
        line-height: 1.6;
        word-break: break-word;
        color: rgb(177, 182, 198);
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
    }

    .modal-content {
        max-height: 75vh;
        overflow: scroll;
    }
}

.exit-box {
    position: sticky;
    z-index: 2;
    top: 26px;
    right: 22px;
    height: 0px;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;

    .exit-icon {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 99px;
        cursor: pointer;
        background: rgb(26, 29, 41);
    }
}
@media only screen and (max-width: 425px) {
    .modal-dialog {
        padding: 16px;
    }
    
    .create-plans {
        padding: 24px 16px;
        width: calc(100vw);

        .rollbot-box {
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
            text-align: center;
            padding: 30px 10px !important;
        }
    }

    .how-it-work-dialog {
        padding: 15px;
    }
}