.form-account-left {
    background-image: url('../../../../../public/media/images/bg_2.jpg');
    line-height: 1.6;
    background-size: cover;
    background-position: 48% 50%;
    border-radius: 0px 10px 10px 0px;
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 13px;
    width: 50%;
}

.form-account-right {
    font-size: 12px;
    font-family: "Geogrotesque Wide", sans-serif;
    color: rgb(177, 182, 198);
    font-weight: 500;
    font-style: normal;
    cursor: pointer;
    user-select: none;
    width: 50%;
    padding: 24px;

    .label-custom {
        text-transform: uppercase;
        font-weight: 800;
        font-style: normal;

        span {
            color: rgb(255, 73, 73);
            vertical-align: middle;
            font-size: 12px
        }
    }

    .tab {
        display: flex;
        gap: 12px;
        margin-bottom: 24px;
    }

    .other-login {
        display: flex;
        justify-content: center;
        gap: 12px;
    }
}

.modal-login {
    width: 840px;
    margin: auto;
}

.btn-exit {
   position: absolute;
   top: 20px;
   right: 20px; 
   z-index: 2;
   cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .form-account-right,
    .form-account-left {
        width: 100%;
    }

    .modal-content-body {
        flex-direction: column;
    }
}