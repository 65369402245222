.subNav-wrapper {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 12px;
    white-space: nowrap;
    overflow: auto hidden;
    margin-bottom: 25px;

    &::-webkit-scrollbar {
        display: none;
    }

    .btn-subNav {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        height: 40px;
        min-height: 40px;
        padding: 0px 16px;
        border-radius: 8px;
        color: rgb(177, 182, 198);
        background: rgba(203, 215, 255, 0.03);
        transition: all 0.1s ease 0s;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
        text-transform: none;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
        border: none;
        .svg-icon {
            fill: currentColor;
            // margin-right: 8px;
        }
        &:hover {
            background: rgba(203, 215, 255, 0.075);
        }
    }
    .btn--active  {
        background: rgba(203, 215, 255, 0.075);
        color: rgb(255, 255, 193);
        text-shadow: rgb(255, 93, 0) 0px 0px 10px;
        cursor: default;
    }

    .btn--active .svg-icon svg {
        color: rgb(255, 255, 193);
        filter: drop-shadow(rgb(255, 93, 0) 0px 0px 6px);
    }
}