
.table-container {
    width: 100%;
    overflow: auto;
    
    .table-custom {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px 4px;

        thead {
            z-index: 2;
            position: relative;
            background: rgb(31, 35, 48);
            border-radius: 4px;

            th {
                color: rgb(103, 109, 124);
                padding: 0 15px;
            }
        }
    
        tbody {
            tr {
                border-radius: 4px;
                color: rgb(255, 255, 255);
                background: rgba(203, 215, 255, 0.03);
            }

            td {
                padding: 0 15px;

                & a {
                    color: white;
                }
            }
        }
    
        th,
        td {
            height: 40px;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            font-size: 12px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 700;
            font-style: normal;

            &:first-of-type {
                padding-left: 24px;
                border-radius: 4px 0 0 4px;
            }

            &:last-of-type {
                padding-right: 24px;
                border-radius: 0 4px 4px 0;
            }
        }
    }
}

.p-holder {
    display: flex;
    margin-top: 10px;

    .p-btn {
        cursor: pointer;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        font-weight: 400;
        font-size: 12px;
        padding: 2px 8px;
        margin: 2px;
        border: none;
        background-color: transparent;

        &.active {
            border-radius: 2px;
            background-color: #00E701;
            text-shadow: 0 0 5px #fff;
            color: black;
        }
    }
}

@media only screen and (max-width: 768px) {
    .table-custom {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px 4px;
    
        thead th {    
            padding: 0 8px;
        }
    
        tbody td {
            padding: 0 8px;
        }
    
        th, td {
            &:first-of-type {
                padding-left: 16px;
            }
    
            &:last-of-type {
                padding-right: 16px;
            }
        }
    }
}