.footer-container {
    margin-top: auto;
    background-color: rgb(20, 22, 34);
    width: 100%;

    .footer-wrapper {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        padding: 55px 30px;
        font-size: 14px;
        max-width: 1020px;
        margin: 0px auto;

        .content-column {
            display: flex;
            flex-direction: column;

            .description {
                font-size: 10px;
                margin-bottom: 16px;
                max-width: 310px;
                color: #b1b6c6;;
            }

            .age-text {
                margin-top: -5px;
                text-align: center;
                line-height: 50px;
                height: 50px;
                width: 50px;
                border-radius: 99px;
                color: rgba(177, 182, 198, 0.25);
                font-size: 28px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 800;
                font-style: normal;
            }

            .title {
                color: rgb(255, 255, 255);
                font-weight: 800;
                font-size: 12px;
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            .link-item {
                margin-bottom: 10px;
                font-weight: 500;
                cursor: pointer;
                color: rgb(177, 182, 198);

                &:hover {
                    color: rgb(255, 176, 24);
                    text-decoration: underline !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) { 
    .footer-container {
        .footer-wrapper {
            padding: 55px 16px;
            flex-wrap: wrap;

            .content-column {
                width: 50%;
                margin-bottom: 30px;

                &:first-of-type {
                    width: 100%;
                    margin: 20px 0px 0px;
                    order: 100;
                }
            }
        }
    }
}