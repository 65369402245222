//
// Dark Sidebar(used by Dark Sidebar layout only)
//

[data-kt-app-layout="dark-sidebar"] {
    .app-sidebar {
        background-color: $app-sidebar-dark-bg-color;
        border-right: 0 !important;
        top: 70px;
        height: calc(100vh - var(--kt-app-header-height));

        .hover-scroll-overlay-y {        
            @include scrollbar-color($app-sidebar-dark-scrollbar-color, $app-sidebar-dark-scrollbar-color-hover);
        }

        .app-sidebar-logo {
            // border-bottom: 1px dashed $app-sidebar-dark-separator-color;
            .siderber_btn-group  {
                display: flex;
                justify-content: space-around;
                width: 100%;
                .btn {
                    height: 36px;
                    padding: 0px 12px !important;
                    transition: all 0.1s ease 0s;
                    letter-spacing: 0.5px;
                    cursor: pointer;
                    border-radius: 8px;
                    font-size: 14px;
                    font-family: "Geogrotesque Wide", sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    text-transform: none;
                    color: $app-text-color-b1b6c6;
                    background: rgba(203, 215, 255, 0.075);
                    &:hover {
                        background-color: rgba(203, 215, 255, 0.1);
                    }
                }
            }

            .coin-cost-info {
                display: flex;
                .cost {
                    color: $app-text-color-b1b6c6;
                }
            }
        }

        .btn-custom {
            @include button-custom-variant(
                $color:#B5B5C3, 
                $icon-color: #B5B5C3, 
                $border-color: null, 
                $bg-color: rgba(63, 66, 84, 0.35), 
                $color-active: #B5B5C3, 
                $icon-color-active: null, 
                $border-color-active: null, 
                $bg-color-active: rgba(63, 66, 84, 0.35)
            );
        }

        .menu {
            .menu-item {                    
                .menu-heading {
                    color: $app-sidebar-dark-menu-heading-color !important;
                }

                @include menu-link-default-state( 
                    $title-color: #9D9DA6, 
                    $icon-color:#C5C5D8, 
                    $bullet-color:#787887, 
                    $arrow-color: #787887, 
                    $bg-color: null  
                );
                
                @include menu-link-hover-state( 
                    $title-color: var(--kt-primary-inverse), 
                    $icon-color: var(--kt-primary-inverse), 
                    $bullet-color: var(--kt-primary-inverse), 
                    $arrow-color: var(--kt-primary-inverse), 
                    $bg-color: null
                );
                
                @include menu-link-here-state( 
                    $title-color: var(--kt-primary-inverse), 
                    $icon-color: var(--kt-primary-inverse), 
                    $bullet-color: var(--kt-primary-inverse), 
                    $arrow-color: var(--kt-primary-inverse), 
                    $bg-color: null
                );
                
                @include menu-link-show-state( 
                    $title-color: var(--kt-primary-inverse), 
                    $icon-color: var(--kt-primary-inverse), 
                    $bullet-color: var(--kt-primary-inverse), 
                    $arrow-color: var(--kt-primary-inverse), 
                    $bg-color: null
                );
                
                @include menu-link-active-state( 
                    $title-color: var(--kt-primary-inverse), 
                    $icon-color: var(--kt-primary-inverse), 
                    $bullet-color: var(--kt-primary-inverse), 
                    $arrow-color: var(--kt-primary-inverse), 
                    $bg-color: $app-sidebar-dark-menu-link-bg-color-active
                );
            }
        }
    } 
}