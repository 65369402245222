.user-menu {
    position: absolute;
    display: flex;
    transition: opacity 200ms;
    visibility: visible;
    top: 100%;
    right: 95px;
    .cancel {
        position: fixed;
        width: 100vw;
        height: 100vh;
        cursor: default;
        top: 0;
        left: 0;
    }
    &:target {
        visibility: visible;
        opacity: 1;
    }
}

.user-menu-content {
    border-radius: 6px;
    width: 200px;
    padding: 20px 16px 18px ;
    background-color: rgb(31, 35, 48);
    position: relative;

    .menu-item {
        &:hover {
            color: rgb(255, 255, 255);
            background: rgba(203, 215, 255, 0.055);
            border-radius: 8px;
        }
    }

    .link-active {
        .link-title,
        .svg-icon  {
            color: rgb(255, 255, 193);
            text-shadow: rgb(255, 93, 0) 0px 0px 10px;
        }
    }
}

.link-box {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 16px 0px 6px;
    font-size: 14px;
    margin-bottom: 8px;
    height: 40px;
    min-height: 40px;
    border-radius: 8px;
    color: rgb(255, 255, 255) !important;
    background: rgba(203, 215, 255, 0.03);
    transition: all 0.1s ease 0s;
    font-weight: 500;
    font-style: normal;
    cursor: pointer;
    user-select: none;
}

.icon-box {
    width: 40px;
    display: flex;
    justify-content: center;
}

.menu-icon {
    color: white !important;
    width: 20px;
    height: 20px;
}

.text-grey-1 {
    color: #b1b6c6;
}

@media only screen and (max-width: 768px) {
    .user-menu {
        top: 50%;
        right: 16px;
    }
}
