.connect-wallet-modal,
.chain-network-modal {
    padding: 16px;
    max-width: 360px;
    max-height: 600px;
    overflow: scroll;
    
    .modal-title {
        font-size: 18px;
        font-weight: 600;
    }

    .wallet-list {
        margin: 16px 0;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .wallet-item {
            border-radius: 8px;
            padding: 8px;
            border: 1px solid #3a3a3a;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;

            &:hover {
                background: #ffffff0d;
            }

            .wallet-title {
                text-align: center;
                flex: 1;
            }
        }
    }
}