.my-nft {
    .nft-title {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 24px;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        word-break: break-word;
        gap: 12px;
        font-size: 22px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
    }

    .subNav-wrapper {
        margin-bottom: 0;
        margin-right: auto;
        margin-bottom: 24px;
    }

    .btn-subNav {
        background: rgba(203, 215, 255, 0.03);
        text-transform: uppercase;
        transition: all 0.1s ease 0s;
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
        cursor: pointer;
        user-select: none;
    }

    .search-container {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        padding-bottom: 24px;
    }

    .my-loans {
        .search-container {
            max-width: 1040px;

            .button-green {
                height: 40px;
                padding: 0 20px;
                border-radius: 8px;
                font-weight: 800;

                img {
                    width: 20px;
                    margin: 0px 6px 0px -2px;
                }
            }
        }
    }

    .external {
        .search-container {
            max-width: 1040px;
        }
    }
}