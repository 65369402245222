.spaces {
  $spaces: (
    0: 0px,
        1: 1px,
        2: 2px,
        3: 3px,
        4: 4px,
        5: 5px,
        6: 6px,
        8: 8px,
        7: 7px,
        9: 9px,
        10: 10px,
        11: 11px,
        12: 12px,
        13: 13px,
        14: 14px,
        15: 15px,
        16: 16px,
        17: 17px,
        18: 18px,
        19: 19px,
        20: 20px,
        21: 21px,
        22: 22px,
        23: 23px,
        24: 24px,
        25: 25px,
        26: 26px,
        28: 28px,
        29: 29px,
        30: 30px,
        32: 32px,
        36: 36px,
        38: 38px,
        40: 40px,
        44: 44px,
        46: 46px,
        48: 48px,
        50: 50px,
        60: 60px,
        70: 70px,
        80: 80px,
        90: 90px,
        99: 99px,
        110: 110px,
        120: 120px,
        130: 130px,
        134: 134px,
        140: 140px,
        150: 150px,
        160: 160px,
        170: 170px,
        177: 177px,
        180: 180px,
        200: 200px,
        220: 220px,
        230: 230px,
        240: 240px,
        260: 260px,
        275: 275px,
        286: 286px,
        300: 300px,
        355: 355px,
        360: 360px,
        460: 460px,
        476: 476px,
        540: 540px,
        600: 600px,
        760: 760px,
        800: 800px,
        840: 840px,
        960: 960px,
        1000: 1000px,
        1040: 1040px,
        1120: 1120px,
  );

  @each $name,
  $value in $spaces {
    &.m-#{$name} {
      margin: $value !important;
    }

    &.mt-#{$name} {
      margin-top: $value !important;
    }

    &.mr-#{$name} {
      margin-right: $value !important;
    }

    &.mb-#{$name} {
      margin-bottom: $value !important;
    }

    &.ml-#{$name} {
      margin-left: $value !important;
    }

    &.mx-#{$name} {
      margin-left: $value !important;
      margin-right: $value !important;
    }

    &.my-#{$name} {
      margin-top: $value !important;
      margin-bottom: $value !important;
    }

    &.p-#{$name} {
      padding: $value !important;
    }

    &.pt-#{$name} {
      padding-top: $value !important;
    }

    &.pr-#{$name} {
      padding-right: $value !important;
    }

    &.pb-#{$name} {
      padding-bottom: $value !important;
    }

    &.pl-#{$name} {
      padding-left: $value !important;
    }

    &.px-#{$name} {
      padding-left: $value !important;
      padding-right: $value !important;
    }

    &.py-#{$name} {
      padding-top: $value !important;
      padding-bottom: $value !important;
    }

    &.h-#{$name} {
      height: $value !important;
    }

    &.min-h-#{$name} {
      min-height: $value !important;
    }

    &.w-#{$name} {
      width: $value !important;
    }

    &.max-w-#{$name} {
      max-width: $value !important;
    }

    &.min-w-#{$name} {
      min-width: $value !important;
    }

    &.fs-#{$name} {
      font-size: $value !important;
    }

    &.lh-#{$name} {
      line-height: $value !important;
    }

    &.fs-#{$name} {
      font-size: $value !important;
    }
    &.brd-#{$name} {
      border-radius: $value !important;
    }

    &.fw-#{$name} {
      font-weight: $name *100 !important;
    }

    &.top-#{$name} {
      top: $value;
    }

    &.right-#{$name} {
      right: $value;
    }

    &.left-#{$name} {
      left: $value;
    }

    &.bottom-#{$name} {
      bottom: $value;
    }
    
    &.gap-#{$name} {
      gap: $value !important;
    }
     
    &.border-radius-#{$name} {
      border-radius: $value;
    }
    &.rotate-#{$name} {
      rotate: $name+deg;
    }

    &.grid-columns-#{$name} {
      grid-template-columns: repeat(auto-fill, minmax($value, 1fr));
    }
  }

  .fw-500 {
    font-weight: 500;
  }

  .fw-700 {
    font-weight: 700;
  }

  .fw-800 {
    font-weight: 800;
  }
}