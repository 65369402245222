.option-box {
    border-radius: 8px;
    background: rgba(203, 215, 255, 0.03);
    display: inline-block;
    padding: 20px;
    cursor: pointer;
    font-family: "Geogrotesque Wide", sans-serif;

    & .option-icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        margin-bottom: 14px;

        .option-icon {
            display: inline-block;
            height: 32px;
            width: auto;
        }
    }

    & .option-name {
        color: rgb(255, 255, 255);
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
    }

    & .option-short-name {
        margin-top: 4px;
        color: rgb(177, 182, 198);
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
    }
}