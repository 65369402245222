.address-bar {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 8px;
    border-radius: 6px;
    word-break: break-word;
    color: rgb(255, 255, 255);
    background: rgba(15, 17, 26, 0.55);
    font-size: 14px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 500;
    font-style: normal;
}