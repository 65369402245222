.cardGame-item-wrapper {
    left: 0px;
    top: 0px;
    height: 100%;

    .cardGame-info {
        padding-top: 10px;
        cursor: pointer;

        .cardGame-bg {
            position: relative;
            width: 100%;
            padding-bottom: 140%;
            transition: transform 0.15s ease-out 0s;
            background: rgba(203, 215, 255, 0.03);
            border-radius: 8px;

            img {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                object-fit: cover;
                cursor: pointer;
            }

            .cardGame-description {
                display: none;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                flex-direction: column;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                text-align: center;
                padding: 12px;
                border-radius: 8px;
                animation: 0.15s ease-in 0s 1 normal none running animation-sqvad5;
                cursor: pointer;
                color: white;
                font-size: 12px;

                .svg-icon {
                    color: white;
                    fill: currentColor
                }
            }

            &:hover {
                transform: translateY(-8px);

                .cardGame-description {
                    transition-delay: 1s;
                    background: rgba(39, 43, 56, 0.95);
                    display: flex;
                }
            }
        }
    }
}

.cardGame-battle-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    background: rgba(203, 215, 255, 0.075);
    border-radius: 8px;
    min-width: 177px;
    // height: 286px;
    min-height: 286px;
    // max-height: 286px;
    
    .cardGame-img {
        position: relative;
        padding-bottom: 100%;

        img {
            position: absolute;
            top: 12px;
            left: 12px;
            width: calc(100% - 24px);
            height: calc(100% - 24px);
            display: block;
            border-radius: 6px;
            cursor: pointer;
            user-select: none;
        }
    }

    .cardGame-content {
        position: relative;
        flex: 1 1 0%;
        min-height: 0px;
        display: flex;
        flex-direction: column;
        padding: 0px 16px 16px;

        .cardGame-content-info {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            min-height: 18px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 12px;

            .info--left {
                margin-right: auto;
                min-width: 65px;
                white-space: nowrap;
                color: rgb(177, 182, 198);
            }

            .info--right {
                margin-left: 4px;
                color: rgb(255, 255, 255);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: clip;
            }
        }

        .btn--available {
            font-size: 12px;
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            transition: all 0.1s ease 0s;
            appearance: none;
            border: none;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            cursor: pointer;
            user-select: none;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            flex-direction: column;
            white-space: normal;
            height: 40px;
            line-height: 12px;
            .description {
                font-size: 10px;
                display: flex;
                align-items: center;
                font-style: italic;
                font-weight: 800;
                margin-top: 2px;

                .live {
                    font-weight: 800;
                    display: inline;
                    margin-left: 4px;
                    color: rgb(255, 73, 73);
                }
            }
        }

        .icon--left,
        .icon--right {
            color: #1a1d29;
            width: 26px;
            min-width: 26px;
            height: auto;
            position: absolute;
            bottom: 52px;
        }

        .icon--left {
            left: -11.5px;
            transform: rotate(270deg);
        }

        .icon--right {
            right: -11.5px;
            transform: rotate(90deg);
        }
    }
}