.challenges-container {
    .select-search {
        .value {
            margin-right: 4px;
        }
    }

    .features-box {
        flex: 1 1 0%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        gap: 12px;
        white-space: nowrap;
        overflow: auto hidden;
        color: rgb(177, 182, 198);


        .btn-subNav {
            background: rgba(203, 215, 255, 0.03);
            text-transform: uppercase;
            transition: all 0.1s ease 0s;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
            cursor: pointer;
            user-select: none;
        }
    }

    .action-box {
        width: 100%;

        .input-search {
            width: 100%;
            height: 40px;
            flex: 1;
        }

        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        padding-bottom: 24px;

        .form-check-label {
            color: rgb(177, 182, 198) !important;
            padding-left: 6px;
            font-size: 14px;
        }

        .action-switch {
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: center;
        }

        .form-check-input {
            width: 32px;
            height: 16px;

            &:checked {
                border: 0;
                background-color: rgb(114, 242, 56);
            }
        }
    }

    .list-card-container {
        display: grid;
        -webkit-box-align: stretch;
        align-items: stretch;
        grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
        gap: 18px 12px;
    }


}

.create-challenges {
    margin: 0px auto;
    width: 100%;
    max-width: 700px;
    .input-container {
            margin-bottom: 30px;
        }
}

.title-box {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 24px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    word-break: break-word;
    gap: 12px;
    font-size: 22px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 800;
    font-style: normal;
}

@media only screen and (max-width: 786px) {
    .challenges-container {
        .list-card-container {
            gap: 12px 6px;
        }
    }
}

