.bets-card-container {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;

    .img-card {
        border-radius: 5px;
        min-width: 44px;
        width: 44px;
        min-height: 44px;
        height: 44px;
    }

    .player-box {
        max-width: 100%;
        padding: 12px 0px 8px;
        color: rgb(255, 255, 255);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 14px;

        .team-box {
            display: inline-block;
            margin-left: 6px;
            margin-right: 5px;
            color: rgb(16, 18, 27);
            border-radius: 4px;
            padding: 0px 4px;
            font-size: 12px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
            background: rgb(255, 176, 24);
        }

    }

    .value-bets {
        color: rgb(114, 242, 56);
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
    }

}