.market-price-wraper {
    padding: 10px 6px 8px;

    .coin-info {
        height: 32px;
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #ffffff;
        padding: 0 8px;
        border-radius: 5px;
        font-weight: 500;

        .coin-title {
            margin: 0px 12px;
            color: #b1b6c6;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .coin-price {
            margin-left: auto;
            font-variant-numeric: tabular-nums;
        }

        &:hover {
            background: #0f111a66;
        }
    }
}

.trading-chart-container {
    display: flex;
    height: 655px;
    min-height: 655px;

    .trading-chart-wrapper {
        height: 100%;
        flex: 1 1 0%;
        min-width: 0px;
    }

    .bet-operations {
        margin-left: 20px;
        padding: 20px;
        border-radius: 10px;
        background: rgb(31, 33, 46);
        max-width: 500px;
    }
}

.trading-content-wrapper {
    .tab-header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btns-right-wrapper {
        .button-list {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            gap: 24px;
            white-space: nowrap;
            overflow: auto hidden;
            height: 40px;

            .btn-item {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                cursor: pointer;
                color: rgb(177, 182, 198);
                font-size: 14px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 500;
                font-style: normal;

                &:hover,
                &:hover .svg-icon {
                    color: rgb(255, 176, 24);
                }
            }
        }
    }

    .custom-tab {
        .nav-content {
            .nav-label {
                text-transform: capitalize;
            }
        }
    }
}

.text-label {
    text-transform: uppercase;
    color: rgb(177, 182, 198);
    font-size: 12px;
    margin: 0px 0px 12px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 800;
    font-style: normal;         
}

.input-range {
    border: none;
    box-shadow: none;
    height: 10px;
    border-radius: 999px;
    background: linear-gradient(90deg, rgb(114, 242, 56) 0%, rgb(255, 176, 24) 50%, rgb(255, 73, 73) 100%);
    appearance: none;
    width: 100%;
    outline: none;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 24px;
        height: 24px;
        border-radius: 999px;
        background-color: #ffffff;
        cursor: grab;
    }
}


.group-btn-up-or-down {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row !important;

    &.manual-tab {
        gap: 2px;
        background: rgba(15, 17, 26, 0.55);
        border-radius: 6px;
        padding: 2px;
    }

    &.roi-modal {
        gap: 20px;
    }

    .btn-up,
    .btn-down {
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        min-width: 108px;
        border-radius: 5px;
        flex: 1 1 0px;
        text-transform: capitalize;

        .svg-icon svg{
            color: #b1b6c6;
        }
    }

    .btn-up.active,
    .btn-up.active .svg-icon svg {
        color: rgb(114, 242, 56);
        background: rgba(114, 242, 56, 0.13);
    }
    
    .btn-down.active,
    .btn-down.active .svg-icon svg {
        color: rgb(255, 73, 73);
        background: rgba(255, 73, 73, 0.13);
    }
}

@media only screen and (max-width: 768px) {
    .trading-chart-container {
        height: 408px;
        min-height: 408px;

    }
    
    .tab-header-container {
        flex-direction: column-reverse;
        align-items: unset !important;
    }

    .table-public-bets-container {
        position: relative;
        overflow: auto hidden;
    }
}