//
// Sidebar Minimize
//

.app-sidebar-logo-minimize {
    display: none;
}

// Sidebar footer
.app-sidebar-footer {
    padding: 25px 20px;
    margin: 25px -16px -20px;
    background-color: rgba(203, 215, 255, 0.03);
    .btn {
        transition: all 0.1s ease 0s;
        letter-spacing: 0.5px;
        cursor: pointer;
        user-select: none;
        height: 40px;
        padding: 0px 20px;
        border-radius: 8px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
        width: 100%;
        font-size: 14px;
        text-transform: uppercase;
        color: rgb(255, 255, 255);
        background: rgba(203, 215, 255, 0.075);
        &:hover {
            background-color: rgba(203, 215, 255, 0.1);
        }
    }
    .group-label {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-top: 20px;
        margin-left: -2px;
    }
}
    
// Desktop sidebar minimize mode
@include app-layout-minimize(app-sidebar) {
    .app-sidebar-logo {
        .app-sidebar-logo-default {
            display: none;
        }

        .app-sidebar-logo-minimize {
            display: inline-block;
        }
    }

    .app-sidebar-wrapper {
        width: var(--kt-app-sidebar-width-actual); 
    }

    .app-sidebar-menu {
        .menu-content,
        .menu-title {
            opacity: 0;
            @include app-layout-transition(opacity);
        }

        .menu-item.show > .menu-sub {
            height: 0;
            overflow: hidden;
            @include app-layout-transition(height);
        }
    }

    .app-sidebar-footer {
        .btn-custom {
            padding-left: 0 !important;
            padding-right: 0 !important;

            .btn-label {
                width: 0;
                display: none;
            }	

            .btn-icon {
                width: auto;
                display: block;
            }
        }
    }
}