@font-face {
    font-family: "Arbutus";
    src:
        local("Arbutus"),
        url("../../../../public/font-style/Arbutus/Arbutus-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "New_Rocker";
    src:
        local("New_Rocker"),
        url("../../../../public/font-style/New_Rocker/NewRocker-Regular.ttf") format("truetype");
}

.home-page-container {
    .banner {
        height: 305px;
        position: relative;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background-position-x: 25%;
        background-position-y: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../../../../public/media/images/home-page/Funbit-Gaming-Blockchain.jpg");
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .banner-title {
            font-family: "Arbutus", sans-serif;
            font-size: 42px;
            font-weight: 800;
            color: rgb(255 255 165);
            text-shadow: rgb(255, 93, 0) 0px 0px 2px;
            filter: drop-shadow(rgb(90, 36, 4) 0px 0px 6px);
            width: 80%;
        }

        .banner-description {
            font-family: "New_Rocker", sans-serif;
            font-size: 32px;
            font-weight: 600;
            margin-top: 30px;
            width: 80%;
            text-shadow: rgb(0, 0, 0) 0px 0px 2px;
            filter: drop-shadow(rgb(0, 0, 0) 0px 0px 6px);
        }
    }
}

.banner-container-homepage {
    margin-bottom: 24px;
    position: relative;
    background-color: #000;
    display: flex;

    .banner-left {
        padding: 24px 48px;

        .banner-tab-group {
            background-color: #1a1a1a;
            border-radius: 100px;
            padding: 12px;
            width: max-content;

            .banner-tab {
                display: flex;
                background-color: #000;
                align-items: center;
                padding: 12px 16px;
                border-radius: 100px;

                .banner-tab-label {
                    color: rgb(247, 147, 26);
                    font-size: 24px;
                    line-height: 28px;
                    margin-left: 12px;
                }
            }
        }

        .banner-subtitle {
            font-size: 18px;
            line-height: 30px;
            margin: 7px 0 36px;
        }

        .banner-group-btn {
            display: flex;
            align-items: center;
            gap: 16px;

            .button {
                height: 52px;
            }
        }
    }

    .banner-right {
        position: absolute;
        right: 0;
        top: 0;

        img {
            width: 600px;
            height: 300px;
        }
    }


}

.ranking-content {
    position: relative;

    .search-box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .group-button {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        background: rgba(15, 17, 26, 0.55);
        border-radius: 6px;
        padding: 2px 4px;
        max-width: fit-content;
        text-align: right;
        height: 40px;
        margin-bottom: 8px;

        .button {
            flex: 1 1 100%;
            border-radius: 4px;
            height: 32px;
            text-transform: capitalize;
            font-size: 12px;
            font-weight: 600;
            padding: 0 16px;
        }
    }

    .brc-20-tab-container {
        .table-container .table-custom {
            tr {
                cursor: pointer;

                &:hover {
                    background: rgba(203, 215, 255, 0.06);
                }
            }

            td,
            th {
                padding: 0 8px;
            }

            td {
                height: 80px;
            }
        }
    }

}

@media only screen and (max-width: 1024px) {
    .home-page-container {
        .banner {
            background-position-x: 50%;

            .banner-title {
                font-size: 30px;
                font-weight: 800;
            }

            .banner-description {
                font-size: 24px;
                font-weight: 600;
            }
        }
    }

    .banner-container-homepage {
        .banner-right {
            top: 32px;

            img {
                width: 450px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ranking-content {
        margin-top: 60px;

        .search-box {
            top: -50px;
            left: 0;
        }
    }
}

@media only screen and (max-width: 425px) {
    .home-page-container {
        .banner {
            background-position-x: 60%;

            .banner-title {
                font-weight: 800;
                width: 100%;
            }

            .banner-description {
                font-weight: 600;
                width: 100%;
            }
        }
    }

    .banner-container-homepage {
        margin-bottom: 12px;

        .banner-left {
            .banner-subtitle {
                font-size: 16px;
            }

            .banner-group-btn {
                .button {
                    height: 40px;
                }
            }
        }

        .banner-right {
            top: 86px;

            img {
                width: 375px;
                height: 275px;
            }
        }
    }

    .ranking-content {
        margin-top: 100px;

        .ant-tabs-nav {
            margin: 0 0 4px 0 !important;
        }

        .search-box {
            top: -90px;
            flex-wrap: wrap;
            gap: 0 8px;

            .group-button-container {
                width: 100%;

                .group-button {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
}