.clans-title {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 24px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    word-break: break-word;
    gap: 12px;
    font-size: 22px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.clans-container {
    .features-box {
        flex: 1 1 0%;
        align-items: center;
        display: flex;
        gap: 12px;
        white-space: nowrap;
        overflow: auto hidden;
        padding-bottom: 24px;
        color: rgb(177, 182, 198);

        .subNav-wrapper {
            margin-bottom: 0;
        }

        .btn-subNav {
            background: rgba(203, 215, 255, 0.03);
            text-transform: uppercase;
            transition: all 0.1s ease 0s;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
            cursor: pointer;
            user-select: none;
        }

    }

}

.public-clans {
    display: grid;
    -webkit-box-align: stretch;
    align-items: stretch;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 18px 12px;
}

.info-clans {
    max-width: 1040px;
    margin: 0 auto;

    .nav-box {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        padding-bottom: 16px;

        .subNav-wrapper {
            margin-bottom: 0;
            margin-right: auto;
        }

        .btn-subNav {
            background: rgba(203, 215, 255, 0.03);
            text-transform: uppercase;
            transition: all 0.1s ease 0s;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
            cursor: pointer;
            user-select: none;
        }
    }
}

.details-clan {
    .details-box {
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        padding: 16px;
        gap: 32px;
        margin-bottom: 16px;

        .balance-box {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            gap: 16px
        }

        .detail {
            min-width: 160px;
            max-width: 180px;
        }
    }

    .game-box {
        margin-bottom: 24px;
        position: relative;
        background-image: url("../../../../public/media/images/clan_bg.webp");
        width: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 56.243%;

        .game-selection {
            height: 100%;
            width: 100%;
            display: flex;
            flex: 1 1 0%;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            background-color: rgb(39, 43, 56);

            .game-des {
                position: absolute;
                top: 0px;
                left: 0px;
                height: 100%;
                width: 100%;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                text-align: center;
                padding: 0px 16px;
                color: rgb(177, 182, 198);
                font-size: 20px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 800;
                font-style: normal;
            }
        }
    }

    .detail-clans-tab {
        position: relative;

        .how-it-work {
            position: absolute;
            right: 0;
            top: 0;
            height: 40px;
            display: flex;
            align-items: center;
        }
    }

}

.action-box-container {
    width: 100%;

    .input-search {
        width: 100%;
        height: 40px;
        flex: 1;
    }

    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    padding-bottom: 24px;

}

.load-more-box {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 48px 0px;

    .button {
        padding: 20px;
    }
}


.how-it-work {
    margin-left: auto;
    text-transform: none;
    font-size: 14px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 700;
    font-style: normal;
    cursor: pointer;
    color: rgb(255, 176, 24);

    &:hover {
        color: rgb(255, 176, 24);
        text-decoration: underline;
    }
}

@media only screen and (max-width: 425px) {

    .clans-container {
        position: relative;

        .features-box {
            justify-content: space-between;

            .how-it-work {
                position: absolute;
                top: 0;
                right: 0;
                height: 33px;
                display: flex;
                align-items: center;
            }
        }

        .public-clans {
            grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
            gap: 12px 6px;
        }

    }

    .list-player {
        width: calc(100vw - 32px);
        overflow: scroll;

    }

    .details-box {
        flex-wrap: wrap;
        gap: 0 !important;

        &>* {
            flex: 1 100%;
        }

        .balance-box {
            margin-top: 16px;
            order: 1;
            flex-direction: column;
            align-items: normal !important;
            flex: 1;
        }

        .detail {
            justify-content: space-between;
            max-width: 100% !important;
        }
    }

}