.casinoPage-container {
    max-width: 1170px;
    padding: 0 24px 48px 24px;
    margin: 0 auto;

    .bg-input {
        background: #141621;
    }
}

.category-wraper {
    .filter-container {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        padding-bottom: 14px;
    }

    .card-game-list {
        display: grid;
        -webkit-box-align: stretch;
        align-items: stretch;
        grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
        gap: 18px 12px;
    }

    .card-game-battle-list {
        display: grid;
        -webkit-box-align: stretch;
        align-items: stretch;
        grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
        gap: 18px 12px;
    }
}

.load-more-box {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 48px 0px;

    .button {
        padding: 20px;
    }
}

@import "./components/card-game/cardGame.scss";
@import "./components/table-bets/tableBets.scss";

@media only screen and (max-width: 786px) {
    .category-wraper {
        .filter-container {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            gap: 12px;
            flex-wrap: wrap;
            padding-bottom: 24px;
        }
    
        .card-game-list {
            gap: 6px;
            grid-template-columns: repeat(auto-fill, minmax(105px, 1fr));
        }
    
        .card-game-battle-list {
            grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
            gap: 12px 6px;
        }
    }
}