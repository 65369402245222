.button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    white-space: nowrap;
    transition: all 0.1s ease 0s;
    appearance: none;
    border: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
    user-select: none;
    font-family: "Geogrotesque Wide", sans-serif;
    font-style: normal;
    transition: all 0.1s ease 0s;

    &-yellow {
        height: 40px;
        padding: 0px 16px;
        border-radius: 8px;
        font-weight: 800;
        font-size: 14px;
        color: rgb(20, 23, 34) !important;
        background: rgb(255, 232, 26) !important;
        box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset, rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(255, 135, 25) 0px 0px 12px inset !important;

        &:hover {
            filter: brightness(110%);
        }
    }

    &-green {
        height: 40px;
        padding: 0px 16px;
        border-radius: 8px;
        font-weight: 800;
        font-size: 14px;
        color: rgb(20, 23, 34);
        background: #00E701;
        box-shadow: rgba(118, 255, 25, 0.4) 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset, rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(59, 198, 14) 0px 0px 8px inset;
        transition: all 0.1s ease 0s;

        &:hover {
            filter: brightness(110%);
        }
    }

    &-green-light {
        height: 24px;
        padding: 0px 8px;
        border-radius: 4px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 12px;
        color: rgb(114, 242, 56);
        cursor: pointer;
        background: rgba(114, 242, 56, 0.13);

        &:hover {
            background: rgba(114, 242, 56, 0.22);
        }
    }

    &-red {
        height: 40px;
        padding: 0px 24px;
        border-radius: 8px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
        font-size: 14px;
        color: rgb(20, 23, 34);
        background: rgb(255, 106, 106);
        box-shadow: rgba(255, 73, 73, 0.42) 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset, rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(255, 55, 55) 0px 0px 15px inset;
    
        &:hover {
            filter: brightness(110%);
        }
    }
    
    &-grey {
        height: 40px;
        border-radius: 8px;
        font-weight: 800;
        text-transform: uppercase;
        color: rgb(255, 255, 255);
        background: rgba(203, 215, 255, 0.03);
        position: relative;
        min-height: 40px;
        letter-spacing: 0px;
        text-align: left;
        font-size: 12px;
        transition: all 0.1s ease 0s;

        &:hover {
            background: rgba(203, 215, 255, 0.055);
        }
    }

    &-grey-light {
        height: 40px;
        padding: 0px 16px;
        border-radius: 8px;
        font-weight: 800;
        font-style: normal;
        font-size: 14px;
        color: rgb(255, 255, 255) !important;
        background: rgba(203, 215, 255, 0.075);

        &:hover {
            background: rgba(203, 215, 255, 0.1)
        }
    }

    &-grey-light-2 {
        height: 40px;
        padding: 0px 20px;
        border-radius: 8px;
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
        text-transform: none;
        color: rgb(177, 182, 198);
        background: rgba(203, 215, 255, 0.03);

        &:hover {
            background: rgba(203, 215, 255, 0.055);
        }
    }

    &-grey-tab {
        height: 40px;
        min-height: 40px;
        padding: 0px 16px;
        border-radius: 8px;
        text-transform: uppercase;
        transition: all 0.1s ease 0s;
        font-size: 14px;
        font-weight: 800;
        font-style: normal;
        user-select: none;
        cursor: pointer;
        background: rgba(203, 215, 255, 0.075);
        color: currentColor;

        &:hover {
            background: rgba(203, 215, 255, 0.1);
        }
    }

    &-grey-tab-active {
        color: rgb(255, 255, 193);
        text-shadow: rgb(255, 93, 0) 0px 0px 10px;
    }

    &-disabled {
        opacity: 0.5;
        cursor: default;

        &:hover {
            filter: brightness(100%);
        }
    }
}

.dropIcon {
    width: 8px !important;
    height: auto;
}

.navbar-icon {
    color: white;
}

.buttons-group-container {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background: rgba(15, 17, 26, 0.55);
    border-radius: 6px;
    padding: 2px;

    .button {
        flex: 1 1 100%;
        border-radius: 4px;
        height: 32px;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 600;
        padding: 0 16px;
        color: #b1b6b6;
    }
}