.frame-content {
    border-radius: 8px;
    background: rgba(203, 215, 255, 0.03);
    padding: 24px;
    margin-bottom: 24px;

    .frame-content-header {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        gap: 8px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
        font-size: 14px;
    }

    .content-body {
        .content-body-item {
            border-radius: 8px;
            background: rgba(203, 215, 255, 0.03);
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            min-height: 50px;
            padding: 0px 16px;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
            cursor: pointer;

            &:hover {
                background: rgba(203, 215, 255, 0.055);
            }
        }
    }
}


.profile-page-container {
    color: #b1b6c6;

    .profile-info {
        display: flex;
        -webkit-box-align: center;
        align-items: center;

        .rank-img {
            width: 70px;
            height: 70px;
        }

        .content-right {
            flex: 1 1 0%;
            min-width: 0px;
            padding-left: 24px;

            .account-name {
                padding-bottom: 16px;
                max-width: 100%;
                color: rgb(255, 255, 255);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 800;
                font-style: normal;
                font-size: 24px;
            }

            .progress-bar {
                width: 100%;
                height: 5px;
                border-radius: 99px;
                background: linear-gradient(90deg, rgb(229, 164, 128) 0%, rgb(229, 164, 128) 0%, rgba(203, 215, 255, 0.1) 0%, rgba(203, 215, 255, 0.1) 100%);
            }

            .description {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-top: 8px;

                .des--right {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    padding-top: 5px;
                    column-gap: 6px;

                    .text-rank {
                        color: #2e98b5;
                        font-weight: 800;
                    }
                }
            }
        }
    }

    .get-started-cotent {
        .frame-content-header {
            .header--right {
                display: flex;
                align-items: center;
                gap: 8px;

            }
        }
    }
}
.setting-box {
    .box-container {
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        padding: 24px;
        margin-bottom: 24px;

        .box-des {
            color: #b1b6c6;
            margin: 0px 0px 16px;
            line-height: 1.6;
            font-size: 16px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
        }

        .box-title {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            margin-right: auto;
            color: rgb(255, 255, 255);
            text-transform: uppercase;
            gap: 12px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
            font-size: 14px;
        }

        .box-header {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            margin-bottom: 24px;
            gap: 16px;


            .show {
                margin-right: auto;
                text-transform: none;
                font-size: 14px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 500;
                font-style: normal;
                cursor: pointer;
                color: rgb(255, 176, 24);

                &:hover {
                    text-decoration: underline;
                }
            }

            .feature-box {
                align-items: center;
                display: flex;
                font-size: 14px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 500;
                font-style: normal;

            }


        }
    }
}

.authenicator-modal {
    max-width: 600px;
    padding: 32px;
    margin: 0 auto;

    .dialog-title {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 24px;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        word-break: break-word;
        gap: 12px;
        font-size: 20px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
    }

    .dialog-des {
        padding-bottom: 16px;
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
    }

    .acc-token {
        .input-search {

            font-weight: 500 !important;
        }
    }

    .acc-token-des {
        color: #b1b6c6;
        line-height: 1.6;
        margin: 10px 0px 16px;
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
    }
    .btn-2fa {
        width: fit-content;
        margin-top: 20px;
    }
    .kyc-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
}

.balences-page-container {
    color: #b1b6c6;

    .balence-item-container {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 12px;
        padding: 15px;
        border-radius: 10px;
        background: rgba(15, 17, 26, 0.55);
    }
}