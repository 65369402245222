.no-entries-box {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            padding: 24px 0px;
    
            .no-entries {
                padding: 24px 32px;
                border-radius: 8px;
                background: rgba(15, 17, 26, 0.55);
                text-align: center;
                text-transform: uppercase;
                color: rgb(103, 109, 124);
                font-size: 16px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 800;
                font-style: normal;
            }
}