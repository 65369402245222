.trade-page-container {
    .chart-container {
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        display: flex;
        -webkit-box-align: stretch;
        align-items: stretch;
        gap: 24px;
        padding: 24px;
        margin-bottom: 24px;
        height: 450px;

        .chart-content {
            flex: 1 1 0%;
            height: 100% !important;
        }

        .operation-content {
            background: rgba(203, 215, 255, 0.03);
            padding: 16px 16px 20px;
            border-radius: 4px;
            width: 240px;
            height: fit-content;
            
            .group-button {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                background: rgba(15, 17, 26, 0.55);
                border-radius: 6px;
                padding: 2px;

                .button {
                    flex: 1 1 100%;
                    border-radius: 4px;
                    height: 32px;
                    text-transform: capitalize;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 0 16px;
                }
            }
        }
    }
}

.wallet-tab {
    position: relative;

    .contract-address {
        top: 0;
        left: 194px;
        position: absolute;
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        display: flex;
        width: calc(100% - 194px);
        -webkit-box-align: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 24px;
        min-height: 40px;
        padding: 10px 24px;

        .contract-title {
            color: rgb(177, 182, 198);
            display: flex;
            flex-wrap: wrap;
        }
    }


    .coin-network {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 24px;
        padding: 16px;
        color: rgb(255, 255, 255);
        border-radius: 6px;
        background: rgba(203, 215, 255, 0.03);
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;

        .button-grey {
            height: 30px;
            min-height: 30px;
            font-weight: 500;
            background-color: rgba(203, 215, 255, 0.075);
            font-size: 13px;
            padding: 0 14px;
            border-radius: 6px;
            color: rgb(177, 182, 198);
            text-transform: capitalize;

            img {
                border-radius: 50%;
                margin-right: 10px;
                width: 20px;
            }
        }
    }

    .amount-box {
        margin-top: 24px;
        display: flex;
        align-items: flex-end;

        .button-green {
            height: 50px;
            margin-left: 16px;
            white-space: normal;
            text-align: center;
            font-weight: 700;
        }
    }

    .view-transaction {
        font-size: 14px;
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        color: rgb(255, 176, 24);
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

    }

}

    .content-box {
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        padding: 24px;

        .deposit-box {
            display: flex;
        }

        .qr-code {
            width: 150px;
            height: 150px;
            margin-left: 32px;
        }

        .des-box {
            margin-bottom: 24px;
            line-height: 1.6;
            color: rgb(177, 182, 198);
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
        }

        .warning-box {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            column-gap: 12px;
            margin-bottom: 16px;
            line-height: 1.6;
            color: rgb(255, 176, 24);
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;

            .icon-warning {
                color: rgb(255, 229, 0);
                width: 20px;
                min-width: 20px;
                height: auto;
                filter: drop-shadow(rgba(255, 176, 24, 0.6) 0px 0px 5px);
            }
        }

        .other-option {
            font-size: 12px;
            padding-top: 16px;

            span {
                color: rgb(255, 176, 24);
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }


.market-cap-page {
    .chart-list {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }


    .chart-item-wrapper {
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        padding: 24px;
        flex: 1;
        
        .chart-header {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            justify-content: space-between;
            text-transform: uppercase;
            color: #b1b6c6;
            font-size: 13px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
        }

        .progress-bar-wrapper {
            border-radius: 8px;
            background: rgba(203, 215, 255, 0.03);
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            padding: 12px 16px;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
            margin-top: 24px;

            .progress-bar {
                width: 100%;
                height: 3px;
                border-radius: 99px;
                background: linear-gradient(90deg, rgb(254, 138, 1) 0%, rgb(254, 138, 1) 38.5932%, transparent 38.5932%, transparent 39.2932%, rgb(114, 242, 56) 39.2932%, rgb(114, 242, 56) 100%);
            }
        }

        .line-item {
            width: 24px;
            height: 3px;
            border-radius: 99px;

            &.line--orange {
                background-color: #fe8a01;
            }

            &.line--green {
                background-color: #72f238;
            }
        }

        .lable {
            border-radius: 8px;
            background: rgba(203, 215, 255, 0.03);
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            padding: 12px 16px;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
            margin-top: 24px;
        }

        .chart-content {
            height: 200px;
        }
    }
}

.holdings-page-container {
    color: #b1b6b6;
    font-size: 14px;
    font-weight: 500;

    .holding-frame {
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        padding: 24px;
    }

    .holding-chart-container {
        display: flex;
        gap: 64px;

        .chart-content {
            flex: 1 1 100%;
            
            .header-content {
                flex: 1 1 100%;
                display: block;

                .progress-bar {
                    height: 3px;
                    width: auto;
                    border-radius: 99px;
                    background: linear-gradient(90deg, rgb(254, 138, 1) 0%, rgb(254, 138, 1) 10%, rgba(203, 215, 255, 0.1) 0%, rgba(203, 215, 255, 0.1) 100%);
                }
            }
        }
    }

    .holding-item-list {
        margin-top: 16px;
        display: flex;
        gap: 16px;

        .holding-item-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            flex: 1 1 30%;

            .header {
                text-transform: uppercase;
                font-size: 14px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 800;
                font-style: normal;
                padding-bottom: 12px;
                color: #b1b6b6;
            }
        }
    }
}

.add-liquidity {
    display: flex;
    column-gap: 48px;

    .liquidity-form {
        width: calc(50% - 24px);

        .tvl-box {
            margin-top: 16px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            gap: 4px;
            color: rgb(177, 182, 198);
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
        }

        .liquidity-title {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            margin-bottom: 24px;
            color: rgb(255, 255, 255);
            text-transform: uppercase;
            gap: 12px;
            font-size: 18px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
        }
    }

    .liquidity-chart {
        width: calc(50% - 24px);

        .chart {
            background-color: rgb(65, 59, 52);
            width: 100%;
            height: 150px;
            position: relative;

            .line {
                position: absolute;
                height: 150px;
                width: 1px;
                background-color: #fe8a01;
                left: 150px;
            }
        }

        .chart-info {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            color: rgb(103, 109, 124);
            padding-top: 8px;
            font-size: 12px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 600;
            font-style: normal;
            margin-bottom: 23px;
        }

        .liquidity-detail {
            color: rgb(177, 182, 198);
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;

            .liquidity-info-box {
                display: flex;
                flex-wrap: wrap;
                -webkit-box-align: center;
                align-items: center;
                gap: 12px 36px;
                padding-top: 8px;

                .info-title {
                    padding-bottom: 4px;
                    font-family: "Geogrotesque Wide", sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 12px;
                }

                .info-data {
                    color: rgb(255, 255, 255);
                    font-variant-numeric: tabular-nums;
                    font-size: 16px;
                    font-family: "Geogrotesque Wide", sans-serif;
                    font-weight: 700;
                    font-style: normal;
                }
            }
        }
    }
}

.lottery-home-page-container {
    .lottery-jackpot-container {
        display: flex;

        .lottery-jackpot-item {
            flex: 1 1 50%;
            margin-top: 40px;

            .number-item {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                width: 28px;
                height: 32px;
                font-size: 18px;
                font-weight: 800;
                border-radius: 4px;
                margin: 0 2px;
                color: rgb(255, 255, 255);
                background: rgb(16, 18, 27);
            }
        }
    }

    .wager-steps-container {
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        display: flex;
        flex-wrap: wrap;
        margin: 40px 0px 0px;
        padding: 0px 0px 30px;

        .wager-step {
            min-width: 30%;
            padding: 30px 20px 0;

            .step-title {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                margin: 0px 0px 18px;
                text-transform: uppercase;
                color: rgb(255, 255, 255);
                font-size: 18px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 800;
                font-style: normal;
            }
        }

        .step-one {
            min-width: 40%;
        }
    }

    .blocks-mined-container {
        .blocks-mined-item {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 99px;
            background: rgb(16, 18, 27);
            box-shadow: none;

            .svg-icon  {
                color: #5e626f;
            }
        }

        .block-active {
            background: rgb(136, 235, 96);
            box-shadow: rgba(118, 255, 25, 0.4) 0px 0px 7px, rgb(59, 198, 14) 0px 0px 5px inset;
        
            .svg-icon  {
                color: rgb(15, 17, 26);
            }
        }
    }

    .previous-lottery-winners-container {
        margin-top: 40px;
    }

    .verify-link {
        text-decoration: underline;
        transition: all linear 0.25s;
        cursor: pointer;

        &:hover {
            color: #ffb018
        }
    }
}

.current-bets-container {
    .list-bets {
        padding-top: 30px;
        display: grid;
        -webkit-box-align: stretch;
        align-items: stretch;
        grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
        gap: 20px;
    }
}

@media only screen and (max-width: 1124px) {
    .market-cap-page {
        .chart-item-wrapper {
            width: 50% !important;
            padding: 16px;
            flex: 0 0 calc((100% - 16px)/2);
        }
    }
}

@media only screen and (max-width: 786px) {
    .trade-page-container {
        .chart-container {
            flex-direction: column-reverse;
            height: 760px !important;
            padding: 16px !important;
    
            .operation-content {
                width: 100% !important;
            }
        }
    }

    .wallet-tab {
        .contract-address {
            top: 64px;
            left: 0;
            width: 100%;
        }
        .custom-tab {
            .nav {
                padding-bottom: 86px;
            }
        }
    }

    .content-box {
        padding: 16px;
    }

    .lottery-home-page-container .wager-steps-container .wager-step {
        min-width: 50%;
        width: 50%;
    }
}

@media only screen and (max-width: 425px) {
    .market-cap-page {
        .chart-item-wrapper {
            width: 100% !important;
            padding: 16px;
            flex: 1 1 100%;
        }
    }

    .holdings-page-container {
        .holding-chart-container {
           flex-direction: column;
           gap: 40px;
        }

        .holding-item-list .holding-item-wrapper {
            flex: 1 1 100%;
        }
    }

    .wallet-tab { 
        .custom-tab {
            .nav {
                padding-bottom: 130px;
            }
        }
    }

    .deposit-box {
        flex-direction: column;
    }

    .wallet-tab {
        .amount-box {
            flex-direction: column;

            .button-green {
                margin-top: 24px;
                margin-left: 0;
                width: 100%;
            }
        }

        .custom-tab {
            .nav {
                padding-bottom: 130px;
            }
        }
    }

    .add-liquidity {
        flex-direction: column;
        row-gap: 48px;

        .liquidity-form,
        .liquidity-chart {
            width: 100%;
        }
    }

    .lottery-home-page-container {
        .header-lottery-container {
            flex-direction: column;

            .header-title {
                font-size: 30px !important;
                margin-bottom: 10px !important;
            }

            .button {
                width: fit-content;
            }
        }

        .lottery-jackpot-container {
            flex-wrap: wrap;

            .lottery-jackpot-item {
                flex: 1 1 100%;
            }
        }

        .wager-steps-container .wager-step {
            min-width: 100%;
            width: 100%;
        }

        .total-wager-container {
            flex-wrap: wrap;
            gap: 40px;

            .verify-item-container, 
            .total-wager-item {
                flex: 1 1 100%;
            }
        }
    }
}