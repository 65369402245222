.select-search {
    position: relative;
}

.select-box {
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    transition: all 0.1s ease 0s;
    appearance: none;
    border: none;
    letter-spacing: 0.5px;
    cursor: pointer;
    user-select: none;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: none;
    background: rgba(203, 215, 255, 0.03);
    padding: 0px 12px 0px 16px;
    max-width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    color: rgb(177, 182, 198);
    
    .label {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .value {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin: 0px 0px 0px 4px;
        min-width: 0px;
        color: rgb(255, 176, 24);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

}

.select-popup {
    z-index: 1001;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 8px -4px, rgba(0, 0, 0, 0.18) 0px 0px 20px 0px, rgba(0, 0, 0, 0.35) 0px 40px 34px -16px;
    top: 20px;
    width: 200px;
    background-color: rgb(31, 35, 48);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    &:target {
        visibility: visible;
        opacity: 1;
    }
}