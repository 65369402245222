.slider-content {
    display: flex;
    gap: 12px;
    overflow: overlay hidden;
    scroll-behavior: smooth;
    flex-wrap: nowrap;
    will-change: scroll-position;
    --scroll-duration: 3s;
    &::-webkit-scrollbar {
        display: none;
    }
}

.slider-nowrap {
    display: grid;
    -webkit-box-align: stretch;
    align-items: stretch;
    gap: 18px 12px;
    flex-wrap: wrap;
}

.grid-columns-150 {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.grid-columns-168 {
    grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
}

.slider-header {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px 16px;
    margin: 33.5px 0px 3.5px;
    color: white;
    .slider-header--left {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
        .title {
            cursor: pointer;
        }
        .btn-viewAll {
            text-transform: none;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 700;
            font-style: normal;
            cursor: pointer;
            color: rgb(255, 176, 24);
            margin-left: 16px;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .title {
        color: white;
        display: flex;
    }
    .btn-group {
        color: white;
        display: flex;
        align-items: center;
        gap: 6px;
        .btn-left,
        .btn-right {
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            white-space: nowrap;
            transition: all 0.1s ease 0s;
            appearance: none;
            border: none;
            letter-spacing: 0.5px;
            cursor: pointer;
            user-select: none;
            height: 24px;
            padding: 0px 13px;
            border-radius: 4px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 12px;
            text-transform: uppercase;
            color: rgb(255, 255, 255);
            background: rgba(203, 215, 255, 0.03);
            &:hover {
                background: rgba(203, 215, 255, 0.055);
            }
        }
        .btn-right {
            transform: rotate(180deg);

            .svg-icon {
                margin-bottom: 2px;
            }
        }
    }
}

@media only screen and (max-width: 786px) {
    .slider-nowrap {
        display: grid;
        -webkit-box-align: stretch;
        align-items: stretch;
        gap: 6px;
        flex-wrap: wrap;
    }
}