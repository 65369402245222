.textarea-wrapper {
    resize: none;
    background-color: #191b26;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: #424242;
    border-radius: 6px;
    min-height: 32px;
    font-size: 14px;
    padding: 6px 12px;
    color: #ffffff;

    &:focus {
        border-color: #cba044;
        box-shadow: 0 0 0 2px rgba(241, 200, 129, 0.17);
        outline: 0;
    }
}