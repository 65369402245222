.inscription-detail-page {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 64px 32px 32px;
    gap: 32px;

    .content--left {
        width: 40%;

        .content-container {
            width: 100%;
            text-align: center;
            background-color: #131516;
            border-radius: 20px;
            padding: 56px 16px;
            font-size: 14px;
            word-wrap: break-word;      /* IE 5.5-7 */
            white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
            white-space: pre-wrap;      /* current browsers */
        }

        .button-buy {
            margin-top: 24px;
            padding: 10px 16px;
            width: 100%;
            color: #fff;
            transition: all .5s ease-in-out;
            background: linear-gradient(90deg,#eac249,#df7a03);
            position: relative;
            cursor: pointer;
            border-radius: 8px;
            font-size: 15px;
            font-weight: 500;

            &:hover {
                background: linear-gradient(90deg,#eac249 20%,#df7a03 80%);
                top: -1px;
            }
        }
    }

    .content--right {
        width: 60%;

        .panel {
            overflow: hidden;
            background: hsla(0,0%,100%,.06);
            border-radius: 20px;
            padding: 16px 24px;
            font-size: 14px;
            flex: 1;
            font-weight: 500;

            .desc-item {
                padding: 16px 0;

                .key {
                    color: #b1b1b1
                }

                .value {
                    display: block;
                    margin-top: 8px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .link {
                    color: #1668dc;
                    transition: .1s linear;
                    cursor: pointer;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid hsla(0,0%,100%,.03);
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .inscription-detail-page {
        flex-direction: column;
        padding: 48px 16px 16px !important;

        .content--left,
        .content--right {
            width: 100%;
        }

        .panel-group{
            flex-wrap: wrap;

            .panel {
                flex: 1 1 100%;
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .inscription-detail-page {
        .content--left .content-container {
            font-size: 13px;
        }
    }
}