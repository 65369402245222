.nft-loans {
    color: #b1b6c6;
    max-width: 960px;
    margin: -24px auto 0 auto;

    .nft-container {
        padding: 36px 0;
        width: 100%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        border-radius: 8px;
        background: radial-gradient(at 50% 0px, rgba(1, 255, 57, 0.2) 0%, transparent 75%);

        .feature-box {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;

            .feature-item {
                border-radius: 8px;
                background: rgba(203, 215, 255, 0.03);
                display: flex;
                flex-direction: column;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                height: 64px;
                padding: 0px 24px;

                .item-top {
                    color: rgb(255, 255, 255);
                    font-size: 16px;
                    font-family: "Geogrotesque Wide", sans-serif;
                    font-weight: 800;
                    font-style: normal;
                }

                .item-bottom {
                    padding-top: 4px;
                    font-size: 12px;
                    font-family: "Geogrotesque Wide", sans-serif;
                    font-weight: 500;
                    font-style: normal;
                }
            }
        }

        .nft-title {
            color: rgb(255, 255, 255);
            max-width: 500px;
            font-size: 38px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;

            .title-fees {
                color: rgb(114, 242, 56);
            }
        }

        .nft-image {
            width: 281px;
            margin-right: -16px;
        }

        .nft-des {
            padding: 16px 0px 32px;
            line-height: 1.6;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
        }
    }

    .steps-box {
        margin: 0 auto;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        width: 100%;

        .step {
            position: relative;
            width: 33.333%;
            padding-bottom: 6.37%;
            background-image: url("../../../../../public/media/images/home-page/steps_box.png");
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;

            .step-inner {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                padding-right: 40px;
                line-height: 14px;
                font-size: 14px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 500;
                font-style: normal;

                .step-number {
                    padding: 0px 8px 0px 48px;
                    color: rgb(255, 255, 193);
                    text-shadow: rgb(255, 93, 0) 0px 0px 10px;
                    font-size: 16px;
                    font-family: "Geogrotesque Wide", sans-serif;
                    font-weight: 800;
                    font-style: normal;
                }
            }
        }
    }

    .external-wallet {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 12px;
        padding: 40px 0 16px;

        .total-loans {
            text-transform: none;
            color: rgb(177, 182, 198);
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 14px;

            .connect-wallet {
                color: rgb(103, 109, 124);
            }
        }
    }

    .install-metamask-box {
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        padding: 16px;

        .box-inner {
            display: flex;
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            padding: 48px 24px;
            border-radius: 8px;
            background: rgb(26, 29, 41);

            .box-des {
                padding: 24px 0 32px;
                line-height: 1.6;
                text-align: center;
                color: rgb(177, 182, 198);
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 14px;
            }
        }
    }
    .support-collect {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 12px;
        padding: 40px 0px 16px;
    }
    .collection-table {
        .text-grey {
            color: rgb(177, 182, 198);
        }
    }
}

@media only screen and (max-width: 786px) {
    .nft-loans {
        .steps-box {
            .step .step-inner .step-number {
                padding-left: 32px;
            }
        }
    }
}