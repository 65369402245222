.page-container {
    max-width: 1170px;
    padding: 0 24px 48px 24px;
    margin: 0 auto;
}

.page-title {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 24px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    word-break: break-word;
    gap: 12px;
    font-size: 22px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.app-main {
    height: fit-content;

    .app-content {
        margin: 0px auto;
        width: 100%;
        max-width: 100%;
        padding: 24px 24px 48px;
    }
}

.form-page-container {
    margin: 0px auto;
    width: 100%;
    max-width: 700px;
    padding: 0 24px 48px 24px;
}

.filter-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    padding-bottom: 14px;
}

.text-color-gray {
    color: #b1b6c6 !important;
}

.text-color-green {
    color: #72f238;
}
.bg-color-green {
    background-color: #72f238;
}

.color-yellow-green {
    color: #c9ff2e;
}

.color-turquoise-blue {
    color: #00f0ff;
}

.color-lime-yellow {
    color: #eeff00;
}

.text-color-red {
    color: #ff4949;
}

.text-color-yellow {
    color: #ffb018
}

.text-color-white {
    color: $white;
}

.text-color-black {
    color: $black;
}

.color-orange {
    color: #fe8a01
}

.linear-gradient-text {
    background: linear-gradient(103.92deg,#ebb94c,#e97e00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

// Phần tab
.tab-container {
    display: flex;
    gap: 4px;

    .tab-wrapper {
        display: flex;
    }

    &.nav-tabs {
        border: none;
        height: 100%;

    }

    .nav-item {
        color: rgb(255, 255, 255);
        border-radius: 8px;
        height: 30px;
        display: flex;
        margin-top: 10px;
        align-items: center;
    }

    .nav-link {
        white-space: nowrap;
        transition: all 0.1s ease 0s;
        appearance: none;
        border: none;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        cursor: pointer;
        user-select: none;
        font-family: "Geogrotesque Wide", sans-serif;
        font-style: normal;
        height: 30px;
        padding: 0px 10px;
        font-weight: 800;
        font-style: normal;
        text-transform: uppercase;

        &.active {
            background: rgba(203, 215, 255, 0.03);
            border-radius: 8px;
            color: rgb(255, 255, 193) !important;
            text-shadow: rgb(255, 93, 0) 0px 0px 10px;
        }

        &:hover {
            background: rgba(203, 215, 255, 0.075);
            border-radius: 6px;
        }
    }
}

//trading chart
.tradingview-widget-copyright {
    display: none;
}

//no-found
.no-found-box {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 48px 0px;

    .no-found {
        padding: 24px 32px;
        border-radius: 8px;
        background: rgba(15, 17, 26, 0.55);
        text-align: center;
        text-transform: uppercase;
        color: rgb(103, 109, 124);
        font-size: 16px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
    }
}

.menu-item.btn-collapse {
    height: 0;
    width: 40px;
    position: relative;
    top: -40px;
    left: 176px;

    &.show {
        height: auto;
    }

    .menu-arrow {
        width: 15px;
        margin: 0;
    }

    .menu-sub {
        min-width: 203px;
        position: relative;
        left: -176px;
    }
}

.flex-row-between {
    display: flex;
    justify-content: space-between;
}

.text-underline {
    text-decoration: underline;
}