.card-container {
    border-radius: 8px;
    background: rgba(203, 215, 255, 0.03);
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 16px;
    color: #b1b6c6;

   

    .clan-bg {
        margin-bottom: 16px;
        background-color: rgb(39, 43, 56);
        width: 100%;
        padding-bottom: 100%;
        border-radius: 4px;
        background-image: url("../../../../../../public/media/images/clan_bg.jpg");
        background-position: 50% 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .game-img {
        margin-bottom: 16px;
        background-color: rgb(39, 43, 56);
        width: 100%;
        border-radius: 4px;
        object-fit: cover;
    }

  
}
 .clan-info {
     .clan-name {
         color: rgb(255, 255, 255);
         font-size: 14px;
         font-family: "Geogrotesque Wide", sans-serif;
         font-weight: 800;
         font-style: normal;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis
     }

     .clan-game {
         padding: 4px 0px 16px;
         color: rgb(177, 182, 198);
         font-size: 12px;
         font-family: "Geogrotesque Wide", sans-serif;
         font-weight: 500;
         font-style: normal;

         .game-live {
             color: rgb(255, 73, 73);
             font-family: "Geogrotesque Wide", sans-serif;
             font-weight: 800;
             font-style: italic;
         }
     }
 }
   .detail {
       color: #b1b6c6;
       display: flex;
       -webkit-box-pack: justify;
       justify-content: space-between;
       -webkit-box-align: center;
       align-items: center;
       height: 22px;
       width: 100%;
       gap: 12px;
       font-size: 12px;
       font-family: "Geogrotesque Wide", sans-serif;
       font-weight: 500;
       font-style: normal;
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;

       .pnl-negative {
           color: rgb(255, 73, 73) !important;
       }

       .pnl-positive {
           color: rgb(114, 242, 56) !important;
       }

       div:nth-child(2) {
           color: white;
       }

       .owner-box {
           display: flex;
           -webkit-box-align: center;
           align-items: center;
           cursor: pointer;
           column-gap: 5.15021px;
           font-size: 12px;
           overflow: hidden;
           white-space: nowrap;
           text-overflow: ellipsis;

           .owner-icon {
               display: inline-flex;
               -webkit-box-align: center;
               align-items: center;
               -webkit-box-pack: center;
               justify-content: center;
               min-width: 16px;
               width: 16px;
               min-height: 16px;
               height: 16px;

               img {
                   width: 100%;
               }
           }
       }
   }