.banner-container {
    margin-bottom: 24px;

    .banner-wrapper {
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        align-items: flex-start;
        background-color: rgb(31, 35, 48);
        background-size: auto 100%;
        background-position: 100% 50%;
        background-repeat: no-repeat;
        border-radius: 8px;
        min-height: 210px;
        padding: 24px 32px;
        margin-bottom: 24px;

        &.banner--loans {
            background-image: url("../../../../public/media/images/banner/banner-nfts.webp");
        }

        &.banber--jackpot {
            background-image: url("../../../../public/media/images/banner/banner-jackpot.webp");
            background-color: rgb(33, 28, 48);
        }

        .banner-description {
            max-width: 480px;
            margin-bottom: 22px;
            text-transform: uppercase;
            line-height: 38px;
            color: rgb(255, 255, 255);
            font-size: 32px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: italic;
        }
    }

    .carousel-indicators {
        bottom: -10px;

        [data-bs-target] {
            border: unset;
            width: 24px;
            height: 4px;
            border-radius: 99px;
            background: rgb(255, 255, 193);
            box-shadow: rgb(255, 93, 0) 0px 0px 10px 0px, rgb(255, 252, 57) 0px 0px 1px inset;
        }
    }
}

.nft-win-card-wrapper {
    border-radius: 8px;
    background: rgba(203, 215, 255, 0.03);
    padding: 8px 8px 10px;
    position: relative;
    text-align: center;
    cursor: pointer;
    user-select: none;

    .nft-img {
        width: 100%;
        border-radius: 4px;
    }

}

.Supported-collections-container {
    padding-top: 32px;

    .collection-item-container {
        border-radius: 8px;
        background: rgba(203, 215, 255, 0.03);
        padding: 16px;
        width: calc(50% - 12px);

        .banner {
            padding-bottom: 32.55%;
            border-radius: 8px;
            background-image: url("../../../../public/media/images/nft/collections/banner/Crypto-Punks.webp");
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .collection-list {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        
        .collection-content {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            padding-top: 20px;
    
            .avatar {
                height: 64px;
                width: 64px;
                min-height: 64px;
                min-width: 64px;
                border-radius: 8px;
                background-image: url("../../../../public/media/images/nft/collections/avatar/Crypto-Punks.webp");
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: cover;
            }
    
            .content-body {
                padding-left: 16px;
    
                .name {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    color: rgb(255, 255, 255);
                    text-transform: uppercase;
                    font-family: "Geogrotesque Wide", sans-serif;
                    font-weight: 800;
                    font-style: normal;
                    font-size: 16px;
                }
    
                .description {
                    line-height: 1.6;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-family: "Geogrotesque Wide", sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 14px;
                }
            }
        }
    }

}

@media only screen and (max-width: 786px) {
    .banner-container .banner-wrapper {
        background-position: 50% 50%;
    }

    .Supported-collections-container .collection-item-container {
        width: calc(100% - 12px);
    }
}