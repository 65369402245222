.info-detail-coin-container {
	max-width: 1280px;
	margin: 0 auto;
	font-family: "Geogrotesque Wide", sans-serif;

	.display-domain {
		color: #72f238;
		font-weight: 700;
		font-size: 28px;
		line-height: 1.3333333333;
	}

	.progress-status {
		font-size: 16px;
		color: rgb(138, 138, 138);
	}

	.info-detail-box {
		display: flex;
		justify-content: center;
		flex-direction: column;

		margin-bottom: -1px;
		color: rgba(255, 255, 255, 0.85);
		font-weight: 600;
		font-size: 16px;
		background: transparent;
		border: 1px solid #303030;
		border-radius: 8px;

		.info-detail-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 24px;
			border-bottom: 1px solid #303030;
			min-height: 56px;

			.button-box {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 16px;

				.subNav-wrapper {
					margin: 0;

					.btn-subNav {
						font-size: 16px;

						&:hover {
							border: 1px #ebb94c solid;
						}
					}
				}
			}
		}

		.info-detail-tab {
			.nav {
				padding: 12px 24px;

				.nav-link {
					font-size: 14px;
					text-transform: capitalize;
					font-weight: 700;
				}
			}
		}

		.info-detail-body {
			padding: 24px;
		}
	}
}

.address-link {
	color: #1668dc;
	cursor: pointer;
	text-decoration: underline;
}

.detail-item {
	font-weight: 600;
	text-align: left;
	font-size: 16px;
	line-height: 1.5;
	padding-bottom: 9px;

	.item-title {
		color: rgba(255, 255, 255, 0.45);
	}

	.item-value {
		font-size: 14px;
		margin-bottom: 8px;
		margin-left: 16px;
		word-wrap: break-word;
		font-family: "Geogrotesque Wide", sans-serif;

		&.link {
			color: #1668dc;
			text-decoration: underline;
		}
	}
}

.marketplace-page-container {
	.marketplace-header {
		text-align: center;
		position: relative;

		.header-title {
			font-size: 24px;
			line-height: 28px;
			background: linear-gradient(54deg, #eac249, #df7a03);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.market-tag {
			display: inline-flex;
			align-items: center;
			padding: 4px 8px;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			color: #eee;
			background: hsla(0, 0%, 100%, 0.08);
			border-radius: 6px 6px 6px 0;
			transform: translateY(-16px);
			word-break: keep-all;
			white-space: nowrap;
		}
	}
}

.trade-item-container {
	border: 1px solid rgba(66, 66, 66, 0.8);
	border-radius: 4px;
	overflow: hidden;
	position: relative;
	transition: all 0.25s ease-in-out;
	color: white;
	cursor: pointer;

	&:hover {
		border-color: #ebb94c;
	}

	.content {
		height: 152px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		background: #121212;

		.top {
			position: absolute;
			top: 0;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 8px;

			.name {
				font-weight: 700;
				font-size: 14px;
				line-height: 16px;
			}

			.type {
				padding: 4px 8px;
				background: #312a1a;
				border-radius: 0 4px;
			}
		}

		.inscription-name {
			margin-top: 20px;
			color: #fff;
			font-weight: 600;
			font-size: 24px;
			line-height: 39px;
		}

		.price-line {
			margin-top: 20px;

			.price {
				font-weight: 700;
				font-size: 20px;
				line-height: 23px;
				color: #ebb94c;
			}

			.unit {
				color: #8a8a8a;
				margin-left: 4px;
			}
		}
	}

	.info {
		background-color: #1e1e1e;
		padding: 0 12px 12px 12px;

		.number {
			color: #ebb94cb3;
		}

		.divider {
			width: 100%;
			height: 1px;
			background-color: hsla(0, 0%, 100%, 0.04);
			margin: 8px 0;
		}
	}

	.usd {
		margin-top: 4px;
		font-size: 12px;
		color: hsla(0, 0%, 100%, 0.5);
	}
}

.trade-collection-item-container {
	border: 1px solid rgba(66, 66, 66, 0.8);
	border-radius: 4px;
	overflow: hidden;
	position: relative;
	transition: all 0.25s ease-in-out;
	color: white;
	cursor: pointer;

	&:hover {
		border-color: #ebb94c;
	}

	.content {
		// height: 152px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		background: #121212;

		.top {
			position: absolute;
			top: 0;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 8px;

			.name {
				font-weight: 700;
				font-size: 14px;
				line-height: 16px;
			}

			.type {
				padding: 4px 8px;
				background: #312a1a;
				border-radius: 0 4px;
			}
		}

		.inscription-name {
			margin-top: 20px;
			color: #fff;
			font-weight: 600;
			font-size: 24px;
			line-height: 39px;
		}

		.price-line {
			margin-top: 20px;

			.price {
				font-weight: 700;
				font-size: 20px;
				line-height: 23px;
				color: #ebb94c;
			}

			.unit {
				color: #8a8a8a;
				margin-left: 4px;
			}
		}
	}

	.info {
		background-color: #1e1e1e;
		padding: 0 12px 12px 12px;

		.number {
			color: #ebb94cb3;
		}

		.divider {
			width: 100%;
			height: 1px;
			background-color: hsla(0, 0%, 100%, 0.04);
			margin: 8px 0;
		}
	}

	.usd {
		margin-top: 4px;
		font-size: 12px;
		color: hsla(0, 0%, 100%, 0.5);
	}
}

.coin-basic-info-container {
	margin: 16px 0;

	.basic-info-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 64px;

		.basic-info-content {
			display: flex;
			align-items: center;
			gap: 24px;
			flex: 1 1 100%;

			.coin-info {
				width: 100%;

				.faverites-icon {
					border-right: 1px solid #b1b1b1;

					i {
						font-size: 18px;
						padding: 4px 5.5px;
						border: 1px solid;
						border-radius: 999px;
						margin-right: 16px;
						cursor: pointer;
						transition: all linear 0.25s;
					}

					&:hover i {
						color: #ffb018;
						border-color: #ffb018;
					}
				}

				.progress-line {
					margin-top: 8px;
					width: 100%;
					height: 3px;
					border-radius: 999px;
					background-color: #72f238;
					cursor: pointer;
				}
				progress-box-line: {
					width: 100%;
					height: 3px;
					border-radius: 999px;
					background-color: #ffffff;
				}
			}
		}
	}

	.info-list {
		margin: 50px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.info-item-content {
			width: fit-content;

			.item-value {
				font-size: 20px;
				font-weight: 600;
			}

			.sub-content {
				text-align: center;
				margin: 4px 0 0;
				color: #b1b1b1;
			}
		}
	}
}

.nav-extra-content {
	display: flex;
	gap: 12px;
	margin-bottom: 8px;
}

.brc-20-popover-container {
	.nav-content {
		display: flex;
		cursor: pointer;
		align-items: center;
		height: 40px;
		min-height: 40px;
		padding: 0px 16px;
		border-radius: 8px;
		text-transform: uppercase;
		transition: all 0.1s ease 0s;
		font-size: 14px;
		font-family: "Geogrotesque Wide", sans-serif;
		font-weight: 800;
		font-style: normal;
		-webkit-user-select: none;
		user-select: none;
		background: rgba(203, 215, 255, 0.055);

		.nav-label {
			white-space: nowrap;
		}

		&:hover {
			background-color: rgba(203, 215, 255, 0.03);
		}

		&.active,
		&.active .svg-icon {
			background: rgba(203, 215, 255, 0.03);
			color: rgb(255, 255, 193);
			text-shadow: rgb(255, 93, 0) 0px 0px 8px;
		}

		.icon-item {
			transform: rotate(0.5turn);
		}

		.transitionable {
			transition: all 0.25s ease-in-out;
			margin: 0 5px;
		}
	}
}

.brc-20-popover-content {
	color: #b1b1b1;

	.body {
		overflow: scroll;
		max-height: 500px;
	}

	.tick-rank-container {
		.tick-rank--type {
			display: flex;
			align-items: center;
			gap: 16px;

			.name {
				white-space: nowrap;
			}

			.line {
				border: 0.25px solid #b1b1b1;
				padding: 0 20px;
				flex: 1;
			}
		}

		.tick-rank-list {
			display: flex;
			gap: 12px;

			.tick-choose-item {
				flex: 1 1;
				margin: 16px 0;
				padding: 16px;
				border: 1px solid transparent;
				border-radius: 8px;
				background: #3c3e44;
				transition: all 0.25s ease-in-out;
				position: relative;
				cursor: pointer;
				min-width: 216px;
				gap: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.index {
					position: absolute;
					top: 10px;
					left: 12px;
				}

				.name {
					color: white;
					font-weight: 600;
					font-size: 18px;
					line-height: 19.2px;
					transition: inherit;
				}

				.price-container {
					text-align: right;

					.price {
						color: white;
						font-weight: 700;
						font-size: 18px;
						line-height: 21px;
					}

					.unit {
						font-size: 12px;
					}

					.down-color {
						color: #ff4949 !important;
					}

					.up-color {
						color: #72f238 !important;
					}
				}

				&.active {
					border-color: #ecba4d;
					background: #322d1f;
					box-shadow: 0 2px 15px rgba(235, 185, 76, 0.28);

					.name {
						color: #ecba4d;
					}
				}

				&:hover {
					background: #484a52;
				}
			}
		}
	}

	.table-container {
		thead {
			background: transparent;
		}

		tr {
			cursor: pointer;

			&:hover {
				background: rgba(203, 215, 255, 0.06);
			}

			&.active {
				color: #eac249;
			}
		}
	}

	.bi-star-fill {
		color: white;
		margin-right: 8px;
	}
}

.marketplace-home-page-container {
	.group-button {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		background: rgba(15, 17, 26, 0.55);
		border-radius: 6px;
		padding: 2px;
		max-width: fit-content;
		text-align: right;

		.button {
			flex: 1 1 100%;
			border-radius: 4px;
			height: 32px;
			text-transform: capitalize;
			font-size: 12px;
			font-weight: 600;
			padding: 0 16px;
		}
	}

	.table-custom tbody tr {
		cursor: pointer;
		transition: all linear 0.25s;

		&:hover {
			background: #2a304380;
		}
	}
}

.buy-confirm-modal {
	max-width: 686px;

	.body-content {
		.nft-item-components {
			min-width: 156px;
			margin-bottom: 32px;
			display: inline-block;
			border-radius: 4px;
			overflow: hidden;
			border: 0.5px solid rgba(66, 66, 66, 0.8);
			transition: all 0.25s ease-in-out;

			.content-container {
				min-height: 80px;
				padding: 8px;
				background: #121212;
				display: flex;
				flex-direction: column;
				gap: 8px;
			}

			.info {
				padding: 8px;
				font-size: 14px;
				background-color: hsla(0, 0%, 100%, 0.1);
				text-align: left;
				color: #ffb018;
			}

			.price-line {
				border-top: 1px solid #2a2a2a;
				padding: 8px;
				background-color: hsla(0, 0%, 100%, 0.1);
				gap: 8px;
				display: flex;
				justify-content: center;
				color: #f6ae2d;
				font-size: 15px;
			}
		}

		.choose-text-transform {
			padding: 8px 32px;
			margin: 0 auto 32px auto;
			background: rgba(255, 255, 255, 0.1);
			border-radius: 8px;
			width: fit-content;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 16px;

			.border-line {
				width: 1px;
				background-color: rgba(255, 255, 255, 0.2);
				align-self: stretch;
			}
		}

		.fee-line {
			align-self: stretch;
			display: grid;
			align-items: center;
			grid-template-columns: 1fr 156px 156px;
			grid-gap: 16px;
			gap: 16px;
			margin-top: 8px;
			color: #ffffff;
		}
	}
}

.list-nft-for-sale-modal {
	max-width: 650px;

	.modal-content {
		text-align: center;
		font-weight: 500;

		.title {
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 2px;
		}

		.content-container {
			margin-top: 16px;
			background-color: hsla(0, 0%, 100%, 0.1);
			border-radius: 8px;
			padding: 12px;
		}

		.nft-transferable-list {
			margin: 25px 0;
			display: flex;
			// justify-content:space-between;
			gap: 8px;
			flex-wrap: wrap;

			.nft-transferable-item {
				border-radius: 4px;
				border: 1px solid rgba(66, 66, 66, 0.8);
				cursor: pointer;
				flex: 1 1 20%;
				min-height: 136px;
				transition: all linear 0.2s;
				max-width: 130px;

				.nft-content {
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					position: relative;
					background: #121212;
					padding: 8px;
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;

					.name {
						position: absolute;
						top: 8px;
						left: 8px;
					}

					.label {
						position: absolute;
						top: 0px;
						right: 0px;
						background-color: #312a1a;
						padding: 2px 6px;
						border-bottom-left-radius: 4px;
						border-top-right-radius: 4px;
					}

					.value {
						font-size: 16px;
						font-weight: bold;
					}
				}

				.number {
					padding: 8px;
					background: #1e1e1e;
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
					color: #ebb94cb3;
				}

				.unList {
					color: #ffffff;
					margin-bottom: 0px;
					padding: 8px;
					background: red;
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
					opacity: 1;
				}

				&:hover,
				&.active {
					border-color: #ebb94c;
				}

				&.disabled {
					opacity: 0.6;
					&:hover {
						border-color: rgba(66, 66, 66, 0.8);
					}
				}
			}
		}
	}
}

.inscribe-transfer-modal {
	max-width: fit-content;
	border: 1px solid #b1b1b1;
	box-shadow: 0 0 2px #b1b1b1;

	.fee-rate-list {
		display: flex;
		gap: 6px;
		margin-top: 10px;
		margin-bottom: 24px;

		.fee-rate-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border: 1px solid #8a8a8a;
			border-radius: 8px;
			padding: 10px;
			width: 100px;
			cursor: pointer;
			font-weight: 500;

			.about {
				color: #b1b1b1;
				text-align: center;
			}

			&.active {
				background: #eac249;
				color: #333;

				.about {
					color: #333;
				}
			}
		}
	}

	.disabled {
		opacity: 0.6;
	}

	.button {
		width: 50%;
	}

	.preview-box {
		background: hsla(0deg, 0%, 100%, 0.1);
		padding: 6px;
		border-radius: 4px;
	}
}

.sign-transaction-modal {
	max-width: 400px;
	border: 1px solid #b1b1b1;
	box-shadow: 0 0 2px #b1b1b1;
	padding: 16px;

	.price-box {
		background: hsla(0deg, 0%, 100%, 0.1);
		padding: 6px;
		border-radius: 4px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
	}

	.info-list {
		font-weight: 500;
		background: hsla(0deg, 0%, 100%, 0.1);
		border-radius: 4px;
		padding: 8px;
		margin-bottom: 16px;
	}

	.button {
		width: 50%;
	}
}

@media only screen and (max-width: 450px) {
	.marketplace-page-container {
		.nav-extra-content {
			.select-box {
				.label {
					max-width: 170px;
				}
			}

			margin-bottom: 0;
			gap: 4px;
			// justify-content: space-between;

			.button {
				padding: 0 12px;
			}
		}

		.button {
			font-size: 11px;
		}

		.coin-basic-info-container {
			.basic-info-wrapper {
				flex-wrap: wrap;
				gap: 18px;

				.btn-list-sale {
					flex: 1;

					.button-yellow {
						width: 100%;
					}
				}
			}

			.info-list {
				margin: 24px 0;

				.info-item-content {
					.item-value {
						font-size: 18px;
					}
				}
			}
		}

		.ant-tabs-nav {
			margin-top: 48px !important;
		}

		.subNav-wrapper {
			gap: 4px;
		}

		.button-grey {
			font-size: 11px;
		}

		.marketplace-header {
			.header-title {
				font-size: 20px;
			}

			.market-tag {
				font-size: 16px;
			}
		}
	}

	.ant-tabs-extra-content {
		position: absolute;
		width: 100%;
		bottom: 100%;
	}

	.brc-20-popover-content {
		max-width: 350px;

		.tick-rank-container {
			.tick-rank--type {
				.line {
					display: none;
				}

				margin: 16px 0;
			}

			.tick-rank-list {
				flex-wrap: wrap;
				.tick-choose-item {
					margin: 0;
				}
			}
		}
	}

	.buy-confirm-modal {
		.body-content {
			max-height: 80vh;
			overflow: scroll;

			.fee-line {
				grid-template-columns: 1fr 70px 140px;
				grid-gap: 12px;
			}
		}
	}

	.list-nft-for-sale-modal {
		.modal-content {
			.nft-transferable-list {
				.nft-transferable-item {
					flex: 1 1 30%;
					max-width: 105px;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.marketplace-page-container {
		.marketplace-header {
			margin: 6px 0;
		}

		.subNav-wrapper {
			margin-bottom: 4px;
		}
	}
}
