@import "../../../styles/variable";

.input-search-wrapper {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 40px;
    min-height: 40px;
    width: 100%;
    padding: 0px 5px 0px 15px;
    border-radius: 6px;
    border: 1px solid transparent;
    background: rgba(15, 17, 26, 0.55);
    color: rgb(177, 182, 198);
    transition: background 0.1s ease 0s;

    .input-search {
        width: 100%;
        height: 100%;
        color: white;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        outline: none;
        border: none;
        background: none;

        &::placeholder {
            opacity: 0.6;
            color: rgb(177, 182, 198);
            font-weight: 500;
            font-size: 14px;

        }
    }


    &:focus-within {
        border: 1px solid rgb(255, 255, 193);
        box-shadow: rgb(255, 252, 57) 0px 0px 1px inset, rgb(255, 93, 0) 0px 0px 4px;
    }
}

.input-des {
    margin-top: 8px;
    line-height: 1.4;
    color: rgb(177, 182, 198);
    font-size: 12px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.input-label {
    text-transform: uppercase;
    color: rgb(177, 182, 198);
    margin-bottom: 10px;
    font-size: 12px;
    font-family: "Geogrotesque Wide", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.input-checkbox-wrapper {
    .input-checkbox {
        &:checked+label::before {
            background: rgb(255, 229, 0);
            box-shadow: rgb(255, 176, 25) 0px 0px 9px 0px, rgb(255, 176, 25) 0px 0px 4px inset;
            border: 1px solid rgb(255, 229, 0) !important;
        }
    }

    .label-checkbox {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        height: 18px;
        margin: 0px;
        padding-left: 28px;
        cursor: pointer;
        text-transform: uppercase;
        white-space: nowrap;
        font-size: 13px;
        color: rgb(177, 182, 198);
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;

        .svg-icon {
            color: rgb(177, 182, 198);
        }

        .icon-check {
            fill: currentcolor;
            height: auto;
            // display: none;
            position: absolute;
            top: 4px;
            left: 3px;
            color: rgb(20, 23, 34);
        }

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            height: 18px;
            width: 18px;
            border: 1px solid rgb(48, 53, 69);
            border-radius: 4px;
            background: rgb(16, 18, 27);
        }
    }
}

.input-custom-wrapper {
    display: flex;
    text-align: center;
    background: #151924;
    height: 32px;
    padding: 0px 5px 0px 15px;
    border-radius: 6px;
    border: 1px solid transparent;
    background: #0f111a8c;
    flex: 1 1 0%;
    margin-bottom: 8px;

    .svg-icon-2 {
        display: flex;
        align-items: center;
    }

    .input-custom {
        width: 100%;
        height: 100%;
        color: $white;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        outline: none;
        border: none;
        background: none;
        color: y;

        &::placeholder {
            opacity: 0.6;
            color: rgb(177, 182, 198);
            font-weight: 500;
            font-size: 14px;

        }
    }
}

.input-outline {
    &--yellow:focus-within {
        border: 1px solid rgb(255, 255, 193);
        box-shadow: rgb(255, 252, 57) 0px 0px 1px inset, rgb(255, 93, 0) 0px 0px 4px;
    }

    &--green:focus-within {
        border: 1px solid rgb(114, 242, 56);
        box-shadow: rgb(255, 252, 57) 0px 0px 1px inset, rgb(255, 93, 0) 0px 0px 4px;
    }
}

.proof-checkbox-container {
    input[type=checkbox] {
        display: none;
    }

    .input-proof-checkbox:checked + label::before  {
        background: rgb(114, 242, 56);
    }

    .input-proof-checkbox:checked + label .proof-checkbox-icon{
        left: 18px;
    }

    .label-container {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        height: 16px;
        margin: 0px;
        padding-left: 40px;
        cursor: pointer;
        white-space: wrap;
        color: rgb(177, 182, 198);
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            height: 16px;
            width: 32px;
            border-radius: 99px;
            background: rgba(203, 215, 255, 0.2);
        }

        .proof-checkbox-icon {
            position: absolute;
            left: 2px;
            width: 12px;
            height: 12px;
            border-radius: 99px;
            background: rgb(16, 18, 27);
            transition: left 0.15s ease 0s;
        }
    }
}

.proof-radio-container {
    input[type=radio] {
        display: none;
    }

    .input-radio + .label-wrapper .label-icon {
        display: inline-block;
    }

    .input-radio:checked + .label-wrapper::before {
        background: rgb(255, 229, 0);
        box-shadow: rgb(255, 176, 25) 0px 0px 9px 0px, rgb(255, 176, 25) 0px 0px 4px inset;
        border: 1px solid rgb(255, 229, 0) !important;
    }

    .label-wrapper {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        height: 18px;
        margin: 0px;
        padding-left: 28px;
        cursor: pointer;
        text-transform: uppercase;
        white-space: nowrap;
        color: rgb(177, 182, 198);
        font-size: 13px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;

        .label-icon {
            display: none;
            position: absolute;
            top: 6px;
            left: 6px;
            width: 6px;
            height: 6px;
            border-radius: 99px;
            background: rgb(16, 18, 27);
        }

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            height: 18px;
            width: 18px;
            border: 1px solid rgb(48, 53, 69);
            border-radius: 99px;
            background: rgb(16, 18, 27);
        }
    }
}