.custom-tab {
    color: rgb(177, 182, 198);

    .nav-content {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    .nav {
        gap: 12px;
        padding-bottom: 24px;
    }

    &.vertical-tab {
        display: flex;
        flex-direction: row;

        .nav {
            overflow: hidden;
            background-color: rgba(203, 215, 255, 0.03);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            width: 190px;
            margin-right: 32px;
        }
    }

    &.nav-tabs {
        border: none;
    }

    .nav-item {
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
        cursor: pointer;
        user-select: none;
     

        .nav-icon {
            display: flex;
            justify-content: center;
            height: auto;
        }
    }

    .nav-link {
        display: flex;
        cursor: pointer;
        -webkit-box-align: center;
        align-items: center;
        height: 40px;
        min-height: 40px;
        padding: 0px 16px;
        border-radius: 8px;
        text-transform: uppercase;
        transition: all 0.1s ease 0s;
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
        user-select: none;
        background: rgba(203, 215, 255, 0.055);



        &.active {
            background: rgba(203, 215, 255, 0.03);
            color: rgb(255, 255, 193);
            text-shadow: rgb(255, 93, 0) 0px 0px 8px;

            .svg-icon {
                color: rgb(255, 255, 193) !important;
                filter: drop-shadow(rgb(255, 93, 0) 0px 0px 6px);
            }
        }

        &:hover {
            background-color: rgba(203, 215, 255, 0.03);
        }
    }
}