.cashier-modal {
    width: 805px !important;
    margin: 0 auto;
    padding: 24px 40px 40px;
    font-family: "Geogrotesque Wide", sans-serif;

    .cashier-modal-content {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        padding-bottom: 24px;

        img {
            display: inline-block;
            flex: 0 0 auto;
            line-height: 0;
            height: auto;
            margin: 24px 0px 32px;
        }

        .tab-title {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            margin-bottom: 24px;
            color: rgb(255, 255, 255);
            text-transform: uppercase;
            word-break: break-word;
            gap: 12px;
            font-size: 20px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
        }

        .input-box {
            width: 100%;
            max-width: 220px;
            margin-bottom: 24px;
            height: 50px;

            .input-label {
                margin-bottom: 0;
            }

            .input-search-wrapper {
                height: 50px;
            }
        }

        .des-box {
            color: #b1b6c6;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;

            .link {
                cursor: pointer;
                color: rgb(255, 176, 24);

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }

        .button-green {
            width: 140px;
            margin-top: 40px;
            height: 40px;
            padding: 0px 20px;
            border-radius: 8px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
            font-size: 14px;
        }
    }

}

.options-box {
    .options-label {
        font-size: 22.75px;
        margin-bottom: 24px;
        text-transform: uppercase;
    }

    .options-container {
        display: grid;
        -webkit-box-align: stretch;
        align-items: stretch;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        gap: 12px;
    }

    .deposit-detail,
    .withdraw-detail {
        font-family: "Geogrotesque Wide", sans-serif;

        .deposit-detail-header,
        .withdraw-detail-header {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            margin-bottom: 24px;
            color: rgb(255, 255, 255);
            text-transform: uppercase;
            word-break: break-word;
            gap: 12px;
            flex-wrap: wrap;
            font-size: 20px;
            font-weight: 800;
            font-style: normal;

            .icon-back {
                height: 32px;
                width: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .transactions {
                text-transform: none;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                cursor: pointer;
                color: rgb(255, 176, 24);

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .deposit-detail-body,
        .withdraw-detail-body {
            border-radius: 8px;
            background: rgba(203, 215, 255, 0.03);
            padding: 24px;

            .input-search-wrapper {
                height: 50px;
            }


            .des-detail {
                margin-bottom: 16px;
                line-height: 1.6;
                color: rgb(177, 182, 198);
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
            }

            .warning-box {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                column-gap: 12px;
                margin-bottom: 16px;
                line-height: 1.6;
                color: rgb(255, 176, 24);
                font-size: 14px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 500;
                font-style: normal;

                .icon-warning {
                    color: rgb(255, 229, 0);
                    width: 20px;
                    min-width: 20px;
                    height: auto;
                    filter: drop-shadow(rgba(255, 176, 24, 0.6) 0px 0px 5px);
                }
            }

            .qr-code {
                width: 150px;
                height: 150px;
                margin-left: 32px;
            }

            .other-option {
                padding-top: 16px;

                span {
                    color: rgb(255, 176, 24);
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .button-green {
                margin-left: 16px;
                height: 48px;
                font-weight: 800;
                border-radius: 8px;
                font-size: 14px;
                padding: 0 20px;
                white-space: normal;
                text-align: center;
                min-width: 160px;
            }




        }



        .convert-box {
            border-radius: 8px;
            background: rgba(203, 215, 255, 0.03);
            margin-top: 16px;
            padding: 24px;
        }

        .buy-coin {
            border-radius: 8px;
            background: rgba(203, 215, 255, 0.03);
            display: flex;
            flex-wrap: wrap;
            -webkit-box-align: center;
            align-items: center;
            padding: 24px;
            margin-top: 16px;
            gap: 12px;
            line-height: 1.6;
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;

            .button-grey-light {
                height: 30px;
                font-size: 13px;
                padding: 0 14px;
                font-weight: 700;
                color: white;
                border-radius: 6px;
            }

            .payments {
                display: flex;
                flex-wrap: wrap;
                -webkit-box-align: center;
                align-items: center;
                margin-left: auto;
                gap: 12px;
            }
        }
    }
}

.cashier-tab {

    .tab-wrapper {
        display: flex;
    }

    &.nav-tabs {
        gap: 12px;
        border: none;
        margin-bottom: 24px;

    }

    .nav-item {
        color: rgb(177, 182, 198);
        background: rgba(203, 215, 255, 0.03);
        border-radius: 8px;
    }

    .nav-link {
        white-space: nowrap;
        transition: all 0.1s ease 0s;
        appearance: none;
        border: none;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        cursor: pointer;
        user-select: none;
        font-family: "Geogrotesque Wide", sans-serif;
        font-style: normal;
        height: 40px;
        padding: 0px 16px;

        font-weight: 800;
        font-style: normal;
        font-size: 14px;
        text-transform: uppercase;

        &.active {
            background: rgba(203, 215, 255, 0.03) !important;
            border-radius: 8px;
            color: rgb(255, 255, 193) !important;
            text-shadow: rgb(255, 93, 0) 0px 0px 10px;
        }

        &:hover {
            background: rgba(203, 215, 255, 0.055);
            border-radius: 8px;
        }

    }
}

@media only screen and (max-width: 768px) {
    .cashier-modal {
        width: 805px !important;
        margin: 0 auto;
        padding: 20px 10px 10px;
        font-family: "Geogrotesque Wide", sans-serif;

        .deposit-detail-content-box {
            flex-direction: column;
        }

        .qr-code {
            margin-left: 0 !important;
        }

        .deposit-detail-body {
            padding: 10px !important;
        }

        .custom-tab .nav {
            margin-right: 36px;
        }
        
        .tab-content {
            overflow: scroll;
            max-height: 60vh;
        }

        .buy-coin-item {
            width: 100%;
            text-align: center;
            justify-content: center;
        }

        .options-box .deposit-detail .buy-coin, .options-box .withdraw-detail .buy-coin {
            justify-content: center;
            text-align: center;
        }

        .withdraw-detail {
            .withdrawal-form-container {
                flex-direction: column;
                align-items: start !important;

                .icon-equal {
                    font-size: 16px;
                    font-weight: 800;
                    color: white;
                    height: 30px !important;
                }

                .button {
                    width: 100%;
                    margin-top: 24px;
                    margin-left: 0;
                }
            }
        }
    }
}