
@media only screen and (max-width: 786px) {
    .app-main {
        margin-top: 112px;
        .app-content {
            padding: 16px 16px 24px;
            .app-container {
                padding: 0 !important;
                .page-container {
                    padding: 0;
                }
            }
        }
    }

    .liveWinBar-wrapper {
        .liveWinlist {
            .liveWinItem {
                margin-right: 6px !important;
            }
        }
    }

    .slider-content {
        gap: 6px !important;
    }

    .menu-item.btn-collapse {
        height: 0;
        width: 40px;
        position: relative;
        top: -40px;
        left: calc(100vw - 60px);
    
        .menu-sub {
            min-width: calc(100vw - 35px);
            position: relative;
            left: calc(60px - 100vw);
        }
    }

    .game-battle-page {
        flex-direction: column;

        .page-content {
            margin-top: 30px;

            .tabble-wrapper {
                position: relative;
                overflow: auto hidden;
                width: 100%;

                .table-custom th:first-of-type, 
                .table-custom td:first-of-type {
                    padding-left: 16px;
                }

                .table-custom thead th {
                    padding: 0 8px;
                }

                .table-custom tbody td {
                    padding: 0 8px;
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .app-main {
        .app-content {
            padding: 16px 8px 24px;
        }
    }

}