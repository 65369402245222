.referral-banner {
    .container {
        margin: 0 auto;
        max-width: 1140px;
    }

    margin: 0 -16px;
    background-image: url('../../../../public/media/images/banner/referral-banner.png');
    padding-bottom: 75px;
    padding-top: 50px;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;

    .referral-banner-title {
        width: 100%;
        position: relative;

        .referral-banner-headline {
            font-weight: 600;
            font-size: 19px;
        }
    }

    .referral-steps-container {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .referral-step-entry {
            width: 30%;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            .step-number {
                font-weight: 600;
                border-radius: 50%;
                font-size: 20px;
                width: 45px;
                height: 45px;
                background-color: rgba(38, 222, 184, 0.2);
                color: #00E701;
                border: 2px solid #00E701;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 18px;
                flex-shrink: 0;
            }

            .step-title {
                color: #00E701;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }

}

.referral-action-container {
    padding-top: 0 !important;
    margin-top: -60px;


    .container {

        .referral_section_left {
            width: calc(70% - 10px);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
            background-color: rgb(26, 29, 41);
            border-radius: 5px;
            padding: 25px 45px;

            .referral_section_left_1 {
                width: 45%;
                text-align: center
            }

            .referral_section_left_2 {
                width: 10%;
                text-align: center
            }

            .or-bar-line {
                width: 1px;
                height: 30px;
                background-color: #717171;
                margin: 10px auto
            }

            .referral_section_left_3 {
                width: 45%;
                text-align: center
            }
        }

        .referral_section_right {
            width: calc(30% - 10px);
            position: relative;
            border-radius: 5px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
            background-color: rgb(26, 29, 41);
            padding: 25px 45px;

            .referral-stats {
                display: flex;
                align-items: center;

                svg {
                    width: 30px;
                    margin-right: 16px;
                }
            }
        }
    }
}

.referral-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.btn-primary-outline {
    height: 38px;
    padding: 0 20px;
    background-color: transparent;
    border: 1px solid #00E701;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s;

    &.cp-btn {
        position: relative;
    }

    .btn-text {
        font-weight: 500;
        font-size: 12px;
        color: #00E701;
    }

    svg {
        width: 12px;
        margin-left: 10px;
        color: #00E701;
    }
}

.referral-tab {
    .nav {
        padding-bottom: 8px;

        .nav-link {
            font-size: 12px;
            text-transform: capitalize;
            font-weight: 700;
        }
    }
}

@media only screen and (max-width: 425px) {

    .referral-banner {
        .container {
            padding: 0 30px;

            .referral-banner-headline {
                font-size: 21px;
            }

            .referral-steps-container {
                margin-top: 5px;
                margin-bottom: 20px;

                .referral-step-entry {
                    width: 100%;
                    margin-right: 0;
                    justify-content: flex-start;
                    margin-top: 15px;

                    .step-title {
                        font-size: 12.25px;
                    }

                    .step-desc {
                        font-size: 10.5px;
                    }
                }


            }
        }

    }

    .referral-action-container {
        .referral-section {

            .referral_section_left,
            .referral_section_right {
                .fs-22 {
                    font-size: 19px !important;
                }

                padding: 15px 20px;
                width: 100% ;
            }

            .referral_section_right {
                margin-top: 20px;
            }

            .referral_section_left {
                flex-direction: column;

                &>* {
                    width: 100%;
                }

                .referral_section_left_2 {
                    display: flex;
                    align-items: center;
                    margin: 20px 0;

                    .or-bar-line {
                        width: 100px;
                        height: 1px;
                    }
                }
            }
        }


    }


}