.card-container {
    font-family: "Geogrotesque Wide", sans-serif;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    background: rgba(203, 215, 255, 0.075);
    padding: 12px;
    .darken {
        opacity: 0.4;
    }
    &:hover {
        .referral-info{
            opacity: 1 !important;
        }
    }

    .img-box {
        position: relative;

        img {
            display: block;
            width: 100%;
            border-radius: 6px;
            cursor: pointer;
            user-select: none;
        }
                .referral-info {
                    z-index: 1;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    text-align: center;
                    padding: 12px;
                    color: rgb(255, 255, 255);
                    opacity: 0;
                    transition: opacity 0.15s ease 0s;
                    white-space: pre-wrap;
                    cursor: default;
                    font-size: 12px;
                    font-family: "Geogrotesque Wide", sans-serif;
                    font-weight: 700;
                    font-style: normal;
                }
               
                }

    .card-detail {
        flex: 1 1 0%;
        min-height: 0px;
        padding-top: 12px;

        .detail-title {
             margin: 0px 0px 12px;
            line-height: 1.6;
            font-size: 12px;
          
            font-weight: 500;
            font-style: normal;
        }

        .item-box {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            min-height: 20px;
          
            font-weight: 500;
            font-style: normal;
            font-size: 12px;

            .item-title {
                margin-right: auto;
                min-width: 65px;
                white-space: nowrap;
                color: rgb(177, 182, 198);
            }

            .item-value {
                margin-left: 4px;
                color: rgb(255, 255, 255);
              
                font-weight: 500;
                font-style: normal;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: clip;
            }
        }

        .creator-box {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            min-height: 20px;
          
            font-weight: 500;
            font-style: normal;
            font-size: 12px;
            cursor: pointer;
            user-select: none;
            .creator-title {
                    margin-right: auto;
                        min-width: 65px;
                        white-space: nowrap;
                        color: rgb(177, 182, 198);
            }

            .creator-detail {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                cursor: pointer;
                column-gap: 5.15021px;
                font-size: 12px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                img {
                    display: inline-flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    width: 18px;
                    height: 15px;
                }
            }
        }
    }
        .featured-box {
            position: absolute;
            top: 12px;
            right: 10px;
    
            img {
                width: 70px;
            }
    
            span {
                position: absolute;
                top: 6px;
                right: 7px;
                color: rgb(255, 255, 255);
                text-transform: uppercase;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 800;
                font-style: normal;
                font-size: 9px;
            }
        }
}