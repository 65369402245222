.marketplace-container {
    .marketplace-title {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 24px;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        word-break: break-word;
        gap: 12px;
        font-size: 20px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;

    }

    .btn-subNav {
        font-size: 12px;
        font-weight: 800;
        text-transform: uppercase;
    }

    .search-box {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        padding-bottom: 24px;

        .input-custom-wrapper {
            min-width: 170px;
        }
    }

    .for-sale-container {
        display: grid;
        -webkit-box-align: stretch;
        align-items: stretch;
        grid-template-columns: repeat(auto-fill, minmax(214.8px, 1fr));
        gap: 18px 12px;
    }

    .my-sales,
    .my-purchases {
        .search-box {
            max-width: 1040px;
        }
    }

    .nft-box {
        .loading-spin {
            height: 240px;
            width: 100%;
            font-size: 20px;
            border: 1px solid;
            border-radius: 8px;
            font-weight: 800;
            color: #b1b6c6;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .lootbox-header {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            color: rgb(255, 255, 255);
            padding: 12px 0px 24px;
            text-transform: uppercase;
            font-size: 16px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
        }

        .lootbox-content {
            display: grid;
            -webkit-box-align: stretch;
            align-items: stretch;
            grid-template-columns: repeat(auto-fill, minmax(214.8px, 1fr));
            gap: 12px;
            padding-bottom: 50px;

            .card-loss {
                border-radius: 8px;
                background: rgba(203, 215, 255, 0.03);
                position: relative;
                display: flex;
                flex-direction: column;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                justify-content: space-between;
                height: 100%;

                .card-header {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: justify;
                    justify-content: space-between;
                    padding: 0px 16px;
                    width: 100%;
                    height: 40px;
                    background: rgba(203, 215, 255, 0.03);
                    border-radius: 8px 8px 0px 0px;
                    font-size: 12px;
                    font-family: "Geogrotesque Wide", sans-serif;
                    font-weight: 700;
                    font-style: normal;

                    .status,
                    .status {
                        text-transform: uppercase;
                        font-family: "Geogrotesque Wide", sans-serif;
                        font-weight: 700;
                        font-style: normal;
                        font-size: 12px;
                    }

                    .rate {
                        color: rgb(255, 73, 73);
                    }
                }

                .card-content {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    padding: 16px;
                    width: 100%;
                    flex: 1 1 0%;
                    min-height: 0px;

                    .card-img {
                        width: 180px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .marketplace-container {
        .for-sale-container {
            grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
            gap: 12px 6px;
        }
    }
}