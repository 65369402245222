.provider-card-wrapper {
    height: 120px;
    min-height: 120px;
    max-height: 120px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 24px 20px 36px;
    background: linear-gradient(0deg, rgba(203, 215, 255, 0.055) 0%, rgba(203, 215, 255, 0.03) 100%);
    color: #676d7c;
    .provider-img {
        display: block;
        width: 100%;
        max-height: 60px;
        object-fit: contain;
    }
    .games-total {
        position: absolute;
        bottom: 12px;
        color: rgb(103, 109, 124);
        font-size: 12px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
    }
}

.provider-list {
    display: grid;
    -webkit-box-align: stretch;
    align-items: stretch;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 18px 12px;
}

@media only screen and (max-width: 786px) {
    .provider-list {
        gap: 12px 6px;
    }
}