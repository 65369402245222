.faq-page-container {
    .faq-item-container {
        .faq-item-header {
            border-radius: 8px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            column-gap: 8px;
            margin-bottom: 12px;
            padding: 16px 24px;
            color: rgb(177, 182, 198);
            cursor: pointer;
            font-size: 16px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
            background: rgba(203, 215, 255, 0.03);
            
            &:hover {
                background: rgba(203, 215, 255, 0.055);
            }
        }


        .faq-item-content {
            display: none;
            padding: 0px 24px 24px;
            line-height: 1.6;
            color: rgb(177, 182, 198);
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
        }
    }

    .faq-item-active {
        .faq-item-header { 
            background: rgba(203, 215, 255, 0.055);
        }
        
        .faq-item-content {
            display: block;
        }

        .svg-icon  {
            transition: transform 0.1s ease 0s;
            transform: rotate(180deg);
        }
    }
}

.footer-link {
    color: #ffb018;

    &:hover {
        color: #ffb018;
        text-decoration: underline !important;
    }
}