.message-container {
    position: relative;
    padding: 8px;
    margin-bottom: 8px;
    background: rgba(203, 215, 255, 0.03);
    border-radius: 6px;
    color: rgb(177, 182, 198);

    .user {
    -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        column-gap: 5.5794px;
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-flex;
        vertical-align: middle;
        margin-right: 5px;
        max-width: 100%;

        .user-icon {
        display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            min-width: 19.5px;
            width: 19.5px;
            min-height: 19.5px;
            height: 19.5px;
            img {
                width: 80%;
            }
        }

        .username {
            color: white;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .message-content {
            vertical-align: middle;
                line-height: 22px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 13px;
    }

}
