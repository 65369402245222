.tableBets-wrapper {
    margin: 33.5px 0px 16px;
    overflow: hidden;

    .header {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px 16px;
        margin-bottom: 13.5px;

        .title--left {
            color: rgb(255, 255, 255);
            font-size: 14px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
        }

        .header--right {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            column-gap: 6px;
            max-width: 100%;

            .tab-item {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                height: 24px;
                min-height: 24px;
                padding: 0px 8px;
                border-radius: 4px;
                color: rgb(103, 109, 124);
                transition: all 0.1s ease 0s;
                font-size: 12px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 700;
                font-style: normal;
                cursor: pointer;
                user-select: none;

                &:hover {
                    background: rgba(203, 215, 255, 0.03);
                }
            }

            .tab--active {
                color: white;
                background: rgba(203, 215, 255, 0.03);
                cursor: default;
            }
        }
    }

    .body {
        position: relative;
        overflow: auto hidden;
    }
    
    .content {
        position: relative;

        tbody > tr:nth-of-type(2n+1) {
            animation: 0.5s ease-out 0s 1 normal none running animation-13rvueg;
        }

        tbody > tr:nth-of-type(2n) {
            animation: 0.5s ease-out 0s 1 normal none running animation-1pw8ltw;
        }

        &::after {
            background-image: linear-gradient(rgba(26, 29, 41, 0) 0%, rgb(26, 29, 41) 75%);
            content: "";
            z-index: 2;
            position: absolute;
            bottom: 0px;
            left: 0px;
            height: 30%;
            width: 100%;
            pointer-events: none;
        }
    }
}