.stacking-header-container {
    background: #282b36;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    padding: 40px 0;
    margin: -24px;

    .stake_text_1 {
        font-weight: 600;
        font-size: 24px;
    }

    .principle-staking {
        font-size: 20px;
        color: #fff;
        font-weight: 500;

        .coin_name {
            font-size: 14px;
            color: #707070;
        }
    }

    .principle-staking-text {
        font-size: 14px;
        font-weight: 500;
        color: #ebebeb;
        margin-top: 10px;
    }

    .content-right {
        gap: 12px;
        
        .content-right-box-item {
            text-align: center;
            margin-top: 15px;
            padding: 20px 30px;
            border-radius: 8px;
            background-color: #1f2330;
            flex: 1 1 30%;
        }
    }

    .staking-box {
        background-color: #1f2330;
        padding: 20px 15px;
        border-radius: 6px;
    }
}

.staking-summary-group {
    border: 1px solid #707070;
    padding: 8px 20px;
    border-radius: 3px;
    transition: all .3s linear;

    .collapse-item.expand {
        // height: 100px;
        border-bottom: 1px solid #707070;
        padding-bottom: 10px;
        margin-bottom: 10px;

        .staking-summary-group-value {
            margin-bottom: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .coin_name {
                font-size: 12px;
                font-weight: 500;
                color: #707070;
                margin-left: 10px;
                flex-basis: 1;
            }
        }
    }
}

.content-line-right {
    .stake_text_4 {
        font-weight: 600;
        font-size: 12px;
        color: #707070
    }

    padding: 30px;
    border-radius: 5px;
    background-color: #1f2330;

    .content-line-right_line1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content-line-right_line1_left {
            font-weight: 600;
            font-size: 18px;
        }

    }

    .content-line-right_line2 {
        width: 100%;
        margin-top: 25px;
        display: flex;

        .content-line-right_line2_left {
            width: 60%;
            border-right: 1px solid #717171;
            padding-right: 40px;

            .content-line-right_line2_left_content2 {
                width: 100%;
                position: relative;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }

            .content-line-right_line2_left_content3 {
                width: 100%;
                margin-top: 5px;

                .period-btn,
                .period_box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .period_box {
                    width: 100%;
                    flex-wrap: wrap;
                }

                .period-btn {
                    width: 22%;
                    font-weight: 500;
                    font-size: .75rem;
                    color: var(--text-gray);
                    height: 38px;
                    border-radius: 5px;
                    border: 1px solid #707070;
                    justify-content: center;
                    cursor: pointer;
                    transition: all .2s;
                    margin-bottom: 10px;
                    margin-left: 5px;
                    margin-right: 5px;

                    &.active {
                        background-color: #00E701;
                        border: 1px solid #00E701;
                        color: black;
                    }
                }

            }
        }

        .content-line-right_line2_right {
            width: 40%;
            padding-left: 40px;
            position: relative;

            .stake_text_6 {
                font-weight: 600;
                font-size: px;

                &.text-green {
                    color: #00E701;
                }
            }

            .content-line-right_line2_right_content1 {
                width: 100%;
                position: relative;
            }

            .content-line-right_line2_right_content2 {
                width: 100%;
                margin-top: 10px;
            }

            .content-line-right_line2_right_content3 {
                width: 100%;
                margin-top: 5px;
            }
        }
    }
}

.stake_reset_icon {
    width: 18px;
    cursor: pointer;
}

.stacking-content-container {
    .content-left {
        gap: 12px;
        margin-bottom: 24px;

        .content-left-box-item {
            margin-top: 15px;
            padding: 20px 30px;
            border-radius: 8px;
            background-color: rgba(203, 215, 255, 0.03);
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
            flex: 1 1 30%;
        }
    }
}

@media only screen and (max-width: 786px) {
    .stacking-header-container {
        width: 100%;
        margin: 0 auto;

        .content-right {
            .content-right-box-item {
                flex: 1 1 100%;
            }
        }
    }

    .stacking-content-container {
        .content-left {
            flex-wrap: wrap;
    
            .content-left-box-item {
                flex: 1 1 100%;
            }
        }
        
    }

    .content-line-right {
        .content-line-right_line2 {
            flex-direction: column;
            flex: 1 1 100%;

            .content-line-right_line2_left {
                width: 100%;
                padding-right: 0;
                padding-bottom: 15px;
                border-right: none;
                border-bottom: 1px solid #717171;
            }

            .content-line-right_line2_right {
                width: 100%;
                padding: 15px 0 0;

                .stake_text_5 {
                    font-size: 14px;
                }
            }
        }


        .period_box {
            .period-btn {
                width: 45% !important;
            }
        }
    }
}