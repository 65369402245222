@font-face {
    font-family: "Proto Mono";
    src:
        local("Proto Mono"),
        url("../../../../public/font-style/Proto.Mono/ProtoMono-Light.otf") format("truetype");
}

.inscribe-page-container {
    * {
        font-family: "Proto Mono", sans-serif !important;
    }

    max-width: 750px;

    .linear-gradient-text {
        background: linear-gradient(103.92deg, #ebb94c, #e97e00);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    .linear-gradient-text.shadow-text {
        letter-spacing: 3.4px;
        text-shadow: 0 2px 20px rgba(234, 194, 73, .55);
    }

    .text-underline {
        text-decoration: underline;

        &:hover {
            text-decoration: underline !important;
            color: #ebb94c;
        }
    }
}

.inscribe-tab-container {
    position: relative;
    padding: 24px;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-start-start-radius: 0;
    border-radius: 20px;
    border: 1px solid hsla(0, 0%, 100%, .2);

    .linear-gradient-blur {
        z-index: -1;
        background: hsla(0, 0%, 100%, .05);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
        border-radius: 20px;
    }

    .notice .link-item {
        text-decoration: underline;
        margin-left: 4px;
        color: rgb(138, 138, 138);

        &:hover {
            text-decoration: underline !important;
        }
    }

    .file-upload-container {
        margin-top: 16px;
        width: 100%;

        .file-uploader {
            width: 100%;

            .ant-upload-select {
                position: relative;
                width: 100%;
                height: 100%;
                text-align: center;
                background: rgba(255, 255, 255, 0.04);
                border: 1px dashed #424242;
                border-radius: 8px;
                cursor: pointer;
                transition: border-color 0.3s;
                border-color: #ebb94c;
                background: rgba(235, 185, 76, .008);
            }

            &:hover {
                background: rgba(235, 185, 76, .012);
                border-color: #bc8715;
            }
        }

        .upload-icon {
            font-size: 100px;
            color: #888888;
        }

        .file-upload-list {
            margin-top: 16px;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid hsla(0, 0%, 100%, .15);
            background: rgba(0, 0, 0, .3);
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-height: 400px;
            overflow-y: scroll;

            .file-upload-item {
                font-size: 14px;
                position: relative;
                padding: 8px 16px;
                border-radius: 8px;
                background: hsla(0, 0%, 100%, .05);
                display: flex;
                justify-content: space-between;
                gap: 16px;

                .right-content {
                    width: calc(100% - 100px);
                }

                .index {
                    width: 22px;
                    height: 22px;
                    text-align: center;
                    border-radius: 2em;
                    background: hsla(0, 0%, 100%, .2);
                    color: hsla(0, 0%, 100%, .8);
                }

                .file-name {
                    white-space: nowrap;
                    overflow: scroll;
                    width: calc(100% - 30px);
                    margin-top: 4px;
                }

                .file-size {
                    white-space: nowrap;
                }

                .remove-file-icon {
                    cursor: pointer;
                    padding: 6px 8px;
                    border-radius: 6px;

                    &:hover {
                        background-color: hsla(0, 0%, 100%, .1);
                    }
                }
            }
        }
    }
}

.orders-tab-container {
    .connect-label {
        color: #ffffff !important;
        padding: 4px 6px;
        border-radius: 4px;
        border: 1px solid #888888;
        font-size: 12px;
        font-weight: 500;
    }
}

.network-fee-container {
    width: 100%;
    margin: 16px 0;
    
    .network-fee-list {
        display: flex;
        gap: 8px;
        margin: 16px 0;

        .network-item {
            width: calc(33.333% - 8px);
            padding: 12px 16px;
            background: hsla(0,0%,100%,.05);
            border-radius: 5px;
            border: 1px solid hsla(0,0%,100%,.05);

            &:hover {
                background-color: hsla(0,0%,100%,.1);
            }

            &.selected {
                border: 1px solid #ffb018;
            }
        }
    }
}

.tick-choose-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 604px;

    .tick-choose-item {
        flex: 1 1;
        padding: 16px;
        border: 1px solid transparent;
        border-radius: 8px;
        background: #3c3e44;
        transition: all .25s ease-in-out;
        position: relative;
        cursor: pointer;
        min-width: 183px;
        gap: 16px;
        display: flex;
        flex-direction: column;

        .tick-name {
            font-weight: 600;
            font-size: 18px;
            line-height: 19.2px;
            transition: inherit;
            color: white;
        }

        .tag {
            border-radius: 4px;
            font-size: 12px;
            background-color: #666;
            padding: 2px 4px;
            color: #ddd;
            display: flex;
            align-items: center;
            gap: 8px;
            width: fit-content;

            &:hover {
                color: #e6a71e;
                background-color: #515151;

                .svg-icon {
                    color: #e6a71e;
                }
            }
        }

        &:hover {
            background: #484a52;
        }

        &.active {
            .tick-name {
                color: #e6a71e;
            }

            border-color: #e6a71e;
            background: #484a52;
            box-shadow: 0 2px 15px rgba(235, 185, 76, .28);
        }
    }
}

.names-popover-container {
    .nav-content {
        display: flex;
        cursor: pointer;
        align-items: center;
        height: 40px;
        min-height: 40px;
        padding: 0px 16px;
        border-radius: 8px;
        text-transform: uppercase;
        transition: all 0.1s ease 0s;
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
        -webkit-user-select: none;
        user-select: none;
        background: rgba(203, 215, 255, 0.055);

        &:hover {
            background-color: rgba(203, 215, 255, 0.03);
        }

        &.active,
        &.active .svg-icon {
            background: rgba(203, 215, 255, 0.03);
            color: rgb(255, 255, 193);
            text-shadow: rgb(255, 93, 0) 0px 0px 8px;
        }

        .icon-item {
            transform: rotate(0.5turn);
        }

        .transitionable {
            transition: all .25s ease-in-out;
            margin: 0 5px;
        }
    }
}

.double-check-step-container {
    .sats-list {
        max-height: 50vh;
        padding: 0 8px;
        overflow-y: auto;
        margin: 16px 0;
        align-self: stretch;

        .sats-item {
            margin-bottom: 12px;
            border-radius: 8px;
            background-color: hsla(0,0%,100%,.1);
            display: flex;
            overflow: hidden;

            .sats-index-container {
                background-color: hsla(0,0%,100%,.05);
                margin-right: 8px;
                padding: 12px 16px;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 24px;
                    background-color: hsla(0,0%,100%,.2);
                }
            }

            pre {
                flex: 1 1;
                line-height: 24px;
                padding: 12px 16px;
                max-width: 500px;
                word-break: break-all;
                white-space: break-spaces;
                margin: 0;
            }
        }
    }
}

.order-modal-container {

    .form-check-input:checked+span,
    .form-check-input:checked+label {
        color: white !important;
    }

    .text-gray {
        color: rgb(138, 138, 138);
    }

    .bg-yellow {
        background-color: #e6a71e;
    }

    * {
        font-family: "Proto Mono", sans-serif !important;
    }

    .line {
        width: 20px;
        height: 0.1px;
        background-color: #4d4c4c;

        &.complete {
            background-color: #e6a71e;
        }
    }

    .complete-icon {
        color: #e6a71e;
    }

    .order-header {
        color: rgb(138, 138, 138);
        text-align: center;

        .title {
            margin-bottom: 16px;
            color: white;
            font-size: 16px;
        }

        .order-id {
            margin-bottom: 12px;
        }

        .order-info {
            display: flex;
            justify-content: center;
            gap: 24px;
            font-size: 12px;
        }
    }

    .order-steps {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;



        .step {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            color: rgb(138, 138, 138);

            .number {
                color: white;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgb(138, 138, 138);

            }

            &.active {
                color: white;

                .number {
                    background-color: #e6a71e;
                }
            }

        }
    }

    .order-payment-info {
        margin-top: 24px;

        .payment-info {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;


        }

    }

    .order-file {
        margin-top: 12px;

        .file-header {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .export-to-csu {
            cursor: pointer;
            display: flex;
            justify-content: center;
            font-size: 12px;
            color: rgb(138, 138, 138);
            align-items: center;
            gap: 2px;
            text-decoration: underline;
            margin: 12px 0 8px 0;
        }

        .view-action {
            cursor: pointer;
        }

        .file-content {
            background: hsla(0deg, 0%, 100%, 0.05);
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;

        }
    }

    .order-progress {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 36px;
        gap: 10px;
        flex-direction: column;

        .progress-bar {
            background-color: #e6a71e;
        }

        .clock-icon {
            font-size: 48px;
            color: #e6a71e;
        }

        .progress-status {
            display: flex;
            gap: 12px;

            .cofirmed {
                color: aqua;
            }

            .uncofirmation {
                color: #e6a71e;
            }

            button {
                background-color: transparent;
                font-size: 12px;
                border-radius: 4px;
            }

            .btn-boost {
                color: #72f238;
                border: 1px solid #72f238;

            }

            .btn-cancel {
                color: white;
                border: 1px solid #4d4c4c;

            }
        }
    }

    .order-payment-method {
        background: hsla(0deg, 0%, 100%, 0.05);
        border: 1px solid hsla(0deg, 0%, 100%, 0.2);
        border-radius: 8px;

        .method {

            .proof-radio-item {
                padding: 8px;
                border-bottom: 1px solid hsla(0deg, 0%, 100%, 0.2);

            }
        }

        .btn-pay {
            margin: 0 auto;
        }
    }
}

@media only screen and (max-width: 450px) {
    .tick-choose-list {
        width: 250px;

        .tick-choose-item {
            width: 100%;
        }
    }

    .inscribe-tab-container {
        .file-upload-container {
            .upload-icon {
                display: none;
            }
        }

        .space-box {
            width: 50px !important;
        }

        .label-title {
            width: 200px !important;
        }
    }

    .network-fee-container {
        .network-item .spaces{
            font-size: 14px !important;
        }
    }

    .order-modal-container {
        .line {
            height: 1px;
            margin-bottom: 16px;
        }

        .order-steps {
            .step {
                flex-direction: column;
            }
        }

        .order-file {

            .file-content {
                .view-action {
                    flex-direction: column-reverse;
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) { 
    .order-modal-container {
        .step {
            .label {
                white-space: nowrap;
            }
        }
    }
}