.modal-dialog {
    height: 100vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-wrapper {
    padding: 32px;
    position: relative;
    margin: 0 auto;
    max-height: 100vh;
    width: 100%;

    .btn-exit {
        position: absolute;
        top: 32px;
        right: 32px;
    }

    .modal-title {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        word-break: break-word;
        gap: 12px;
        font-size: 22px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
    }

    .modal-content {
        overflow: scroll;
        max-height: 80vh;
    }

    .modal-btns {
        display: flex;
        justify-content: center;
    }
}

.how-it-work-modal {
    display: grid;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(260px, 260px));
    gap: 30px;
    margin-bottom: 30px;

    img {
        border-radius: 8px;
        width: 100%;
    }

    .description {
        margin-top: 15px;
        line-height: 1.8;
        text-align: left;
        color: rgb(177, 182, 198);
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
    }
}

.roi-calculator-modal {
    .description {
        margin-bottom: 36px;
        line-height: 1.6;
        color: rgb(177, 182, 198);
        white-space: pre-wrap;
        font-size: 13px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 500;
        font-style: normal;
    }
}

.quick-active-bets-container {
    .title {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        text-transform: uppercase;
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: "Geogrotesque Wide", sans-serif;
        font-weight: 800;
        font-style: normal;
    }
}

@media only screen and (max-width: 768px) {
    .modal-content {
        box-shadow: unset;
    }

    .roi-calculator-modal {
        .roi-calculator-content {
            flex-direction: column;

            .content--left {
                padding-right: 0 !important;
                padding-bottom: 50px;
            }

            .content--right {
                width: 100% !important;
            }
        }
    }

    .modal-wrapper {
        padding: 24px 16px;

        .manual-tab {
            width: 100% !important;
            flex-direction: column;

            .content--left {
                width: 100% !important;
            }

            .content--right {
                margin-left: 0 !important;
            }
        }
    }
}