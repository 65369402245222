//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
	.app-content {
		// margin: 0px auto;
		width: 100%;
		// max-width: 1170px;
		max-width: 100%;
		padding: 24px 24px 48px;
	}

	.color-price-reduce {
		color: $app-color-reduce;
	}

	.color-price-increment {
		color: $app-color-increment;
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.app-content { 
		[data-kt-app-toolbar-enabled="true"]:not([data-kt-app-toolbar-fixed-mobile="true"]) & {
			padding-top: 0;
		}
	}
}