.liveWinBar-wrapper {
    border-radius: 8px;
    background: #1f2330;
    position: relative;
    padding: 16px 16px 14px;
    margin-bottom: 24px;
    .liveWinTab {
        position: absolute;
        z-index: 2;
        top: 0px;
        left: 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 4px 8px;
        border-radius: 8px 0px 4px;
        background: #1f2330;
        .tab-item {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            height: 24px;
            min-height: 24px;
            border-radius: 4px;
            transition: all 0.1s ease 0s;
            font-size: 12px;
            cursor: pointer;
            padding: 0px 4px;
            font-family: "Geogrotesque Wide", sans-serif;
            font-weight: 800;
            font-style: normal;
            color: rgb(103, 109, 124);
        }
        .tab-item--active {
            color: rgb(255, 255, 255);
            cursor: default;
        }
        .live-point {
            margin-right: 6px;
            width: 8px;
            height: 8px;
            border-radius: 99px;
            background: rgb(255, 73, 73);
        }
    }
    .liveWinlist {
        position: relative;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        overflow: hidden;
        .liveWinItem {
            text-align: center;
            cursor: pointer;
            user-select: none;
            width: 66.714px;
            min-width: 66.714px;
            margin-right: 12px;
            .user-info {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                cursor: pointer;
                column-gap: 4.29185px;
                font-size: 10px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-top: 6px;
                width: 100%;
                -webkit-box-pack: center;
                justify-content: center;
                .user-name {
                    color: white;
                    font-weight: 500;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .money-win {
                padding-top: 3px;
                color: rgb(114, 242, 56);
                font-size: 11px;
                font-family: "Geogrotesque Wide", sans-serif;
                font-weight: 500;
                font-style: normal;
            }
        }
    }
}